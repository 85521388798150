const FormHeaderStyles = (theme) => ({
  width: "100%",
  ".header": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  ".button_container": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },

})
export default FormHeaderStyles