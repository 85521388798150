import { oldVersionPostRequest, oldVersionGetRequest, oldVersionDeleteRequest, oldVersionUpdateRequest } from "../../utils/apiHelper";

async function createFeature(data) {
    return oldVersionPostRequest('feature/create', data)
}
async function getFeature(data) {
 
    return oldVersionPostRequest('feature/getAll', data)
}
async function getFeatureById(param) {
    if (param) return oldVersionGetRequest(`feature/${param}`)
}
async function deleteFeatureById(param) {
 
    if (param) return oldVersionDeleteRequest(`feature/${param}`)
}
async function updateFeatureById(id, data) {
   return oldVersionUpdateRequest(`feature/${id}`, data);
}

export {
    createFeature,
    getFeature,
    getFeatureById,
    deleteFeatureById,
    updateFeatureById
}