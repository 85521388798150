const Style = (theme) => ({
    borderRadius: '12px',
    background: theme.palette.containers.card,
    boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.02)',
    padding: '17px',
    '.iconContaner': {
        height: '48px',
        width: '48px',
        borderRadius: '12px',
        marginRight: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default Style;