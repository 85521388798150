
import { getRequest, deleteRequest, postRequest, updateRequest, oldVersionGetRequest, oldVersionPostRequest, oldVersionDeleteRequest, oldVersionUpdateRequest } from "../../utils/apiHelper"

async function getLicense(data) {
    return oldVersionPostRequest('license/getAll', data)
}
async function deleteLicenseById(param) {
    return oldVersionDeleteRequest(`license/${param}`)
}
async function createLicence(data) {
    return oldVersionPostRequest('license/create', data)
}
async function updateLicenceById(id, data) {
    return oldVersionUpdateRequest(`license/${id}`, data);
}
async function getLicenseById(param) {
    if (param) return oldVersionGetRequest(`license/${param}`)
}

export {
    getLicense,
    deleteLicenseById,
    createLicence,
    updateLicenceById,
    getLicenseById
}