import React from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  AvatarGroup,
  Chip,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { CardEditIcon, TrashIcon } from "../../../assets/svg";

const GroupCard = (props) => {
  const { className, data, access, handleEdit, handleDelete } = props;

  //topCard


  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
      spacing={3}
    >
      {
        data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Grid className="cardContainer">
              <Grid container justifyContent={"space-between"} sx={{ mb: 2 }}>
                <Typography variant="h5" className="fw-600" color="text.primaryText">{item.title}</Typography>
                <Grid>
                  <IconButton onClick={() => handleEdit()}><CardEditIcon /></IconButton>
                  <IconButton onClick={() => handleDelete()}><TrashIcon /></IconButton>
                </Grid>
              </Grid>
              <Typography variant="body1" color="text.greyText1" className="mb-2">{item.description}</Typography>
              <Grid container className="tags mb-2">
              {
                item.access && item.access.map((accessItem, i) => (
                  <Chip key={i} label={accessItem} />
                ))
              }
              </Grid>
                
              <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <AvatarGroup max={4} className="users">
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                  <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                  <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                </AvatarGroup>
                <Grid className="dflex aBaseline">
                  <Typography variant="body1" color={"text.primaryText"}>{item.userCount}</Typography>
                  <Typography variant="body1" color={"text.greyText5"} sx={{ pl: 1 }}>users</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      }

    </Grid>
  );
};

// de  fault props
GroupCard.defaultProps = {
  classes: {},
  data: []
};

// prop types
GroupCard.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array
};


export default styled(GroupCard)(Style);
