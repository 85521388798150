import theme from "../../../theme";

const Style = () => ({
  ".popup": {
    padding: "6px",
  },
  '.MuiPaper-root': {
    maxWidth: '387px'
  },
  ".header": {
    padding: "14px 24px",
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ".closeBtn": {
      background: theme.palette.grey.lightest,
      borderRadius: "6px",
      height: "24px",
      width: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  '.body': {
    padding: '24px'
  },
  '.footer': {
    padding: '0px 24px 24px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    '& .MuiButton-root': {
      height: '40px',
      width: '127px'
    }
  },
  '.changePlanBtn': {
    padding: '6px 24px',
    borderRadius: '10px',
    background: `${theme.palette.primary.main}10`,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '10px',
    color: `${theme.palette.primary.main}`,
    height: '28px'
  },
});

export default Style;
