import React from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  styled,
} from "@mui/material";
import Style from "./style";
import Table from "../../components/Table";

const Invoices = (props) => {
  const { className } = props;

  const headers = [
    { field: 'name', label: 'Agency Name' }, { field: 'created_on', label: 'Created On' }, { field: 'email', label: 'Agency Email' }, { field: 'expiry', label: 'Expiry' },
    { field: 'status', label: 'Status' }, { field: 'price', label: 'price  ($)' },
    { field: 'username', label: 'username' }, { field: 'useremail', label: 'User Email' }, { field: 'plan_name', label: 'Plan' },
  ]
  const orderByOptions = [
    {
      field: 'name',
      label: 'Name'
    },
    {
      field: 'date',
      label: 'Date'
    },
    {
      field: 'amount',
      label: 'Amount'
    },
    {
      field: 'status',
      label: 'Status'
    }
  ];

  return (
    <Grid
      container
      className={className}
    >
      <Table
        title={"Invoice Header"}
        headers={headers}
        ordering={"name"}
        orderBy={orderByOptions || []}
        onSearch={(value) => console.log("Search:", value)}
        onSort={(field) => console.log("Sort by:", field)}
        onFilter={(filters) => console.log("Filter applied:", filters)}
        createUrl="/create-invoice"
      />
    </Grid>
  );
};

Invoices.defaultProps = {
  classes: {}
};

Invoices.propTypes = {
  classes: PropTypes.object
};

export default styled(Invoices)(Style);
