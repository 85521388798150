import { createAsyncThunk } from "@reduxjs/toolkit";
import {addonsService} from "../services/addonsService"
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const createAddonsAsync = createAsyncThunk("addons/create", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Addons creating..."))
        const response = await addonsService.createAddons (data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
export const getAddonsAsync = createAsyncThunk("addons/getAll", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching Addons..."))
        const response = await addonsService.getAddons(data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
export const getAddonsByIdAsync = createAsyncThunk("addons/getById", async (param, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching Addons..."))
        const response = await addonsService.getAddonsById(param);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const deleteAddonsByIdAsync = createAsyncThunk("addons/deleteById", async (param, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Deleteing Addons..."))
        const response = await addonsService.deleteAddonsById(param);
   
        dispatch(setLoading(null))
        dispatch(getAddonsAsync())
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const updateAddonsByIdAsync = createAsyncThunk("addons/updateById", async ({ id, data }, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Updating Addons..."));
        const response = await addonsService.updateAddonsById(id, data);
        dispatch(setLoading(null));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
});

