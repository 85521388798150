
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const DeleteConfirmation = ({ open, onClose, onConfirm, title, content,contentStyle }) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiPaper-root': { borderRadius: '12px', } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={contentStyle}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' ,gap:'10px'}}>
        <Button onClick={onClose} color="primary" variant='outlined' sx={{ color: '#64748B', borderColor: '#BBC4CF' }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus variant='contained'>
         Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;

