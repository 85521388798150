const style = () => ({

    ".licence_container": {
        width: "100%",
        // height: "1652px",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 4px 0px #0000000F",
        padding: "20px"
    },
    ".licence_Name": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        paddingTop: "20px",
        gap: "50px"
    },
    ".ActiveStatus": {
        padding: "10px 30px",
        backgroundColor: "#D4F6D2",
        color: "#0E8E2A",
        borderRadius: "7px",
        fontSize: "14px",
        fontWeight: "500",
    },
    ".licence-end": {
        borderBottom: "1px solid #F2F0F0",
        paddingBottom: "10px"
    },
    ".licence-detail": {
        display: "flex",
        flexDirection: "column",
        gap: "10px"
    }
})
export default style