import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import Addons from "../containers/Addons";
import Feature from "../containers/Feature";
import Licence from "../containers/Licence";
import CreateAddonsForm from "../containers/Addons/components/CreateAddonsForm/createAddonsForm";
import CreateFeatureForm from "../containers/Feature/components/CreateFeatureForm/createFeatureForm";
import CreateLicenceForm from "../containers/Licence/CreateLicenceForm/createLicenceForm";
import CombinedLayout from "../layouts/AgencyLayout"; // Adjust the layout import if needed

const productsRoutes = [
    {
        path: '/products',
        element: <ProtectedRoute />,
        children: [
            {
                element: <CombinedLayout />, // Use CombinedLayout for all products routes
                children: [
                    {
                        path: 'addons',
                        children: [
                            {
                                path: '',
                                element: <Addons />
                            },
                            {
                                path: 'create',
                                element: <CreateAddonsForm />
                            },
                            {
                                path: 'edit/:id',
                                element: <CreateAddonsForm />
                            }
                        ]
                    },
                    {
                        path: 'feature',
                        children: [
                            {
                                path: '',
                                element: <Feature />
                            },
                            {
                                path: 'create',
                                element: <CreateFeatureForm />
                            },
                            {
                                path: 'edit/:id',
                                element: <CreateFeatureForm />
                            }
                        ]
                    },
                    {
                        path: 'licence',
                        children: [
                            {
                                path: '',
                                element: <Licence />
                            },
                            {
                                path: 'create',
                                element: <CreateLicenceForm />
                            },
                            {
                                path: 'edit/:id',
                                element: <CreateLicenceForm />
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export default productsRoutes;
