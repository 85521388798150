import React from "react";
import Invite from "../containers/Invite";

import { ProtectedRoute } from "../hoc/ProtectedRoute";
import CombinedLayout from "../layouts/AgencyLayout";

const inviteRoutes = [
    {
        path: '/',
        element: <ProtectedRoute />,
        children: [
            {
                path: '/invite',
                element: <CombinedLayout/>,
                children: [
                    {
                        path: '',
                        element: <Invite />
                    },
                    
                ]
            }
        ]
    }
]

export default inviteRoutes;
