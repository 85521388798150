import { createHashRouter } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import homeRoutes from "./homeRoutes";
import loginRoutes from "./loginRoutes";
import agenciesRoutes from "./agenciesRoutes";
import salesRoutes from "./salesRoutes";
import userRoutes from "./userRoutes";
import invoicesRoutes from "./invoicesRoutes";
// import addonsRoutes from "./addonsRoutes"
// import licenceRoutes from "./licenceRoutes";
// import featureRoute from "./featureRoute"
import productRoutes from "./productRoute"
import agencyAddonsRoutes from "./agencyAddonsRoute"
import inviteRoutes from "./inviteRoutes";
import productsRoutes from "./productsRoutes";
import userManagementRoutes from "./userManagementRoute";

/**
 * Main Routes for the Application
 */
const router = createHashRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      ...loginRoutes,
      ...homeRoutes,
      ...agenciesRoutes,
      ...salesRoutes,
      ...userRoutes,
      ...invoicesRoutes,
      ...productsRoutes,
     ...productRoutes,
     ...agencyAddonsRoutes,
     ...inviteRoutes,
     ...userManagementRoutes,
    ],
  },
]);

export default router;
