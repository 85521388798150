import uiReducer from "./uiReducer";
import agenciesReducer from "./agenciesReducer";
import authReducer from "./authReducer";
import featureReducer from "./featureReducer"
import licenseReducer from "./licenseReducer";
import addonsReducer from "./addonsReducer";
import agencyAddonsReducer from "./agencyAddonsReducer";
import searchReducer from "./searchReducer";
import inviteReducer from "./inviteReducer";
import userReducer from "./userReducer";
import userinfoReducer from "./userinfoReducer";
import subscriptionsReducer from "./subscriptionReducer"
const reducers = {
    agencies: agenciesReducer,
    auth: authReducer,
    feature: featureReducer,
    license: licenseReducer,
    ui: uiReducer,
    addons:addonsReducer,
    agencyAddons:agencyAddonsReducer,
    search:searchReducer,
    Invite:inviteReducer,
    user:userReducer,
    userinfo:userinfoReducer,
    subscriptions:subscriptionsReducer

}

export default reducers