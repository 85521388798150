export const OPERATORS = {
  startswith: "^",
  contains: "",
  search: "@",
  regex: "$",
};

export const rulesToParams = (rules) => {
  const filter_fields = rules?.map((m) => {
    const operator_value = OPERATORS[m.operator] || "";
    return `${operator_value}${m.field}=${m.value}`;
  });
  return encodeURI(filter_fields.join(";"));
};
