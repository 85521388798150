const createFormStyles = (theme) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ".header": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center"
    },
    ".form_container": {
        display: "flex",
        justifyContent: "space-between",
        gap: "1.5rem",
        color: "#64748B",
        fontFamily: "Inter"
    },
    ".form_container_right": {

        // border: "1px solid black",
        flex: 2, /* This will occupy 2/3 of the space */
        // padding: '10px 15px',
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    ".form_container_right_sub": {
        backgroundColor: "#fff",
        // border: "1px solid black",
        flex: 2, /* This will occupy 2/3 of the space */
        padding: '10px 20px',
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "15px"
    },
    ".main_form_container_left": {
        flex: 1,
        /* This will occupy 1/3 of the space */
        display: "flex",
        flexDirection: "column",
        gap: '15px',

    },
    ".form_container_left": {
        backgroundColor: "#fff",
        // border: "1px solid yellow",
        borderRadius: "8px",
        padding: '10px 15px',
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    },
    ".error_text": {
        color: 'red'
    },
    ".tier_container": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: '10px 20px',
    },
    ".from_tier": {
        display: "flex",
        flexDirection: "column",
        paddingTop: "5px"
    },
    ".to_tier": {
        display: "flex",
        flexDirection: "column",
        paddingTop: "5px"
    }

})
export default createFormStyles