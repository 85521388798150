import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Filter as FilterAltIcon, FilterActive } from "../../assets/svg";
import Modal from "../Modal";
import Rules from "./Rules";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { setActiveFilterHeader, setOpenFilter } from "../../store/reducers/uiReducer";

const Filter = (props) => {
  const { headers, onFilter, filter } = props;
  const dispatch = useDispatch();
  const { filterHeader } = useSelector((state) => state.ui);

  const [open, setOpen] = useState(filterHeader);

  const { rules } = filter && filter;

  useEffect(() => {
    setOpen(filterHeader);
  }, [filterHeader]);

  const toggleFilter = () => {
    dispatch(setActiveFilterHeader()); 
    dispatch(setOpenFilter()); 

    setOpen(!open);
  };

  const onFilterChange = (reason, type) => {
    onFilter(reason, type);
    if (reason === "apply" || reason === "clear") {
      setOpen(false);
      dispatch(setActiveFilterHeader(false)); 
      dispatch(setOpenFilter(false)); 
    }
  };

  return (
    <>
      <Grid style={{
        backgroundColor: `${filterHeader ? "#DCE7FF" : "#ffffff"}`,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        gap: "5px",
        paddingRight: "10px"
      }}>
        <IconButton onClick={toggleFilter}>
          {filterHeader ? <FilterActive /> : <FilterAltIcon />}
        </IconButton>
        <Typography style={{ color: `${filterHeader ? '#2563EB' : ''}` }}>
          Filter
        </Typography>
      </Grid>
      {open && (
        <Modal handleClose={toggleFilter} title={"Filter"} fullWidth>
          <Rules headers={headers} rules={rules} onFilter={onFilterChange} />
        </Modal>
      )}
    </>
  );
};

Filter.defaultProps = {
  classes: {},
  filter: {}
};

// prop types
Filter.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object
};

export default Filter;
