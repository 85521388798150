import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Popover,
    Typography,
    styled,
} from "@mui/material";
import Style from "./style";
import { CheckedIcon, FilterRefresh, UnCheckedIcon, ClearAction } from "../../../../assets/svg";

const FilterHeader = (props) => {
    const {
        className,
        selectedFilters,
        setSelectedFilters,
        filterOptions,
        applyFilter
    } = props;

    const [anchorEls, setAnchorEls] = useState({});

    useEffect(() => {
        const initialFilters = filterOptions && filterOptions.reduce((acc, filter) => {
            if (!selectedFilters[filter.key]) {
                acc[filter.key] = [];
            }
            return acc;
        }, {});
        setSelectedFilters((prevFilters) => ({ ...prevFilters, ...initialFilters }));
    }, []);

    const handleOpen = (event, filterKey) => {
        setAnchorEls((prev) => ({
            ...prev,
            [filterKey]: event.currentTarget,
        }));
    };

    const handleClose = (filterKey) => {
        setAnchorEls((prev) => ({
            ...prev,
            [filterKey]: null,
        }));
    };

    const handleFilterChange = (event, filterKey) => {
      
        const { value } = event.target;
        const filterValue = String(value); // Convert value to string
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [filterKey]: prevFilters[filterKey]?.includes(filterValue)
                ? prevFilters[filterKey]?.filter((filter) => filter !== filterValue)
                : [...(prevFilters[filterKey] || []), filterValue]
        }));
    };

    const handleReset = () => {
        const resetFilters = filterOptions.reduce((acc, filter) => {
            acc[filter.key] = [];
            return acc;
        }, {});
        setSelectedFilters(resetFilters);
        applyFilter()
    };


    return (
        <Grid container className={className}>
            <Grid item xs={12} className="filter">
                <Typography variant="body1" className="fw-500 mr-1" color="text.greyText1">
                    Filters
                </Typography>
                {filterOptions?.map((filter) => (
                    <React.Fragment key={filter.key}>
                        <Grid
                            className="filterSelect"
                            onClick={(event) => handleOpen(event, filter.key)}
                        >
                            <Grid>
                                <FilterRefresh />
                            </Grid>
                            <Typography variant="body1" className="fw-500 mr-1 ml5" color="text.greyText1">
                                {filter.label}:
                            </Typography>
                            <Typography variant="body1" className="fw-500" color="text.blueText">
                                {selectedFilters[filter?.key]?.length === 0 ? ' ' : selectedFilters[filter?.key]?.length + '+'}
                            </Typography>
                        </Grid>
                        <Popover
                            id={Boolean(anchorEls[filter.key]) ? "simple-popover" : undefined}
                            open={Boolean(anchorEls[filter.key])}
                            anchorEl={anchorEls[filter.key]}
                            onClose={() => handleClose(filter.key)}
                            className={`${className} popover`}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <Typography variant="body1" className="fw-600">
                                By {filter.label}
                            </Typography>
                            <Grid className="pt-1">
                                {filter?.options?.map((item) => (
                                    <Grid key={item}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<UnCheckedIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                    name={filter.key}
                                                    value={item.value}
                                                    checked={selectedFilters[filter?.key]?.includes(item.value)}
                                                    onChange={(e) => handleFilterChange(e, filter.key)}
                                                />
                                            }
                                            label={item.label}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Button variant="contained" size="small" className="mt-2" fullWidth onClick={
                                () => {
                                    applyFilter()
                                    handleClose(filter.key)
                                }
                            }>
                                Apply
                            </Button>
                        </Popover>
                    </React.Fragment>
                ))}
                <Grid className="clearFilter" onClick={handleReset}>
                    <ClearAction />
                </Grid>
            </Grid>
        </Grid>
    );
};

FilterHeader.propTypes = {
    className: PropTypes.string,
    selectedFilters: PropTypes.object,
    setSelectedFilters: PropTypes.func,
    filterOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string).isRequired,
    })).isRequired,
    applyFilter: PropTypes.func,
};

FilterHeader.defaultProps = {
    className: '',
    applyFilter: () => { },
    selectedFilters: {},
    setSelectedFilters: () => { },
};

export default styled(FilterHeader)(Style);
