import React from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  Typography,
  styled,
  InputAdornment,
} from "@mui/material";
import Style from "./style";
import { BellIcon, FlozyLogo, SearchIcon } from "../../assets/svg";
import { useDispatch } from "react-redux";
import { getSearch } from "../../store/actions/searchAction"; // Import the search action

const AppHeaderComponent = (props) => {
  const { logout, className } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(getSearch({ searchText }));
      setSearchText("");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      className={className}
    >
      <Grid item>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FlozyLogo />
          <Typography variant="h4" sx={{ ml: 1 }} color={"text.primaryText"}>
            Flozy
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            value={searchText}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
            placeholder="Search..."
            size="small"
            sx={{ ml: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <IconButton>
          <BellIcon />
        </IconButton>
        <IconButton onClick={handleClick}>
          <Avatar />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={logout}>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </Grid>
  );
};

const AppHeader = styled(AppHeaderComponent)(({ theme }) => Style(theme));

export default AppHeader;
