import { oldVersionGetRequest, oldVersionPostRequest as postRequest } from "../../utils/apiHelper";

export const inviteService = {
    getInvitedatas
};


async function getInvitedatas(data) {
  
    return await postRequest(`invite/getAll`, data);
  }