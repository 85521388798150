import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import Style from "./style";
import {
  CheckedIcon,
  FilterRefresh,
  UnCheckedIcon,
} from "../../../assets/svg";

const AgencyPayment = (props) => {
  const { className } = props;

  const [status, setStatus] = useState(false);
  const [selectDate, setSelectDate] = useState(false);
  const [paymentMode, setPaymentMode] = useState(false);

  const handleClose = () => {
    setStatus(null);
    setSelectDate(null);
    setPaymentMode(null);
  };

  const statusData = [
    "Paid",
    "Draft",
    "Failed",
    "Refunded",
    "Cancelled",
    "Requested for refund",
    "Deleted",
  ];

  const paymentData = [
    "Card",
    "Digital Payments",
    "echecks",
    "Cash",
    "Paper Check"
  ];

  return (
    <Grid container className={className}>
      <Grid item xs={12} className="filter">
        <Typography
          variant="body1"
          className="fw-500 mr-1"
          color="text.greyText1"
        >
          Filters
        </Typography>
        {/* Status */}
        <Grid
          className="filterSelect"
          onClick={(event) => setStatus(event.currentTarget)}
        >
          <FilterRefresh />
          <Typography
            variant="body1"
            className="fw-500 mr-1 ml5"
            color="text.greyText1"
          >
            Status :
          </Typography>
          <Typography variant="body1" className="fw-500" color="text.blueText">
            Refunded
          </Typography>
        </Grid>
        <Popover
          id={Boolean(status) ? "simple-popover" : undefined}
          open={Boolean(status)}
          anchorEl={status}
          onClose={handleClose}
          className={`${className} popover`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body1" className="fw-600">
            By Status
          </Typography>
          <Grid className="pt-1">
            {statusData.map((item) => (
              <Grid key={item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      name={"status"}
                    />
                  }
                  label={item}
                />
              </Grid>
            ))}
          </Grid>
          <Button variant="contained" size="small" className="mt-2" fullWidth>
            Apply
          </Button>
        </Popover>
        {/* Status */}
        {/* Date */}
        <Grid
          className="filterSelect"
          onClick={(event) => setSelectDate(event.currentTarget)}
        >
          <FilterRefresh />
          <Typography
            variant="body1"
            className="fw-500 mr-1 ml5"
            color="text.greyText1"
          >
            Date :
          </Typography>
          <Typography variant="body1" className="fw-500" color="text.blueText">
            June
          </Typography>
        </Grid>
        <Popover
          id={Boolean(selectDate) ? "simple-popover" : undefined}
          open={Boolean(selectDate)}
          anchorEl={selectDate}
          onClose={handleClose}
          className={`${className} popover`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body1" className="fw-600">
            By Duration{" "}
          </Typography>
          <Grid>
            
          </Grid>
          <Button variant="contained" size="small" className="mt-2" fullWidth>
            Apply
          </Button>
        </Popover>
        {/* Date */}
        {/* Payment Mode : */}
        <Grid
          className="filterSelect"
          onClick={(event) => setPaymentMode(event.currentTarget)}
        >
          <FilterRefresh />
          <Typography
            variant="body1"
            className="fw-500 mr-1 ml5"
            color="text.greyText1"
          >
            Payment Mode :
          </Typography>
          <Typography variant="body1" className="fw-500" color="text.blueText">
            Card
          </Typography>
        </Grid>
        <Popover
          id={Boolean(paymentMode) ? "simple-popover" : undefined}
          open={Boolean(paymentMode)}
          anchorEl={paymentMode}
          onClose={handleClose}
          className={`${className} popover`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body1" className="fw-600">
            By Payment
          </Typography>
          {paymentData.map((item) => (
              <Grid key={item}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      name={"status"}
                    />
                  }
                  label={item}
                />
              </Grid>
            ))}
          <Button variant="contained" size="small" className="mt-2" fullWidth>
            Apply
          </Button>
        </Popover>
        {/* Payment Mode : */}
      </Grid>
    </Grid>
  );
};

AgencyPayment.defaultProps = {};

AgencyPayment.propTypes = {};

export default styled(AgencyPayment)(Style)
