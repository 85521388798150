import { createSlice } from "@reduxjs/toolkit";
import { getuserinfo,passwordreset } from "../actions/UserAction";



const initialState = {
    list: {
        userInfo: [],
        ownedAgencies: [],
        workspace: [],
        reset: "",

    },
};
 
export const userInfoSlice = createSlice({
    name: "UsersInfo",
    initialState,
    extraReducers: (builder) => {
        // Handling getUserInfo action
        builder
            .addCase(getuserinfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getuserinfo.fulfilled, (state, { payload }) => {
                state.list = {
                    ...state.list,
                    ...payload,
                    reset: ""
                };
                state.loading = false;
            })
            .addCase(getuserinfo.rejected, (state) => {
                state.loading = false;
            });

        // Handling passwordreset action
        builder
            .addCase(passwordreset.pending, (state) => {
                state.loading = true;
            })
            .addCase(passwordreset.fulfilled, (state, { payload }) => {
                state.list = {
                    ...state.list,
                    reset: payload
                };
                state.loading = false;
            })
            .addCase(passwordreset.rejected, (state) => {
                state.loading = false;
            });
    },
});

export default userInfoSlice.reducer;
