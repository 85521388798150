import React, { useEffect, useState } from "react";
import { styled, Avatar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../store/actions/UserAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  applyFilter,
  clearFilter,
} from "../../../store/reducers/userReducer";
import Table from "../../../components/Table";
import { rulesToParams } from "../../../components/Filter/filterUtil"; 
import moment from 'moment';
import  Action  from "../../Agencies/Users/Component/Moreaction";
import { useNavigate } from "react-router-dom"
const styles = {
  ".avatar-cell": {
    display: "flex",
    alignItems: "center",
    ".avatar-title": {
      marginLeft: "8px",
    },
  },
};

// const ProfileCell = (props) => {
//   const { item } = props;
//   const { username, avatar_filename } = item || {};
//   return (
//     <div className="avatar-cell">
//       <Avatar alt={username} src={avatar_filename} />
//       <span className="avatar-title">{username}</span>
//     </div>
//   );
// };
const Name = (item) => {

  return <span>{item.rowData.name
  }</span>;
};
const Email = (item) => {

  return <span>{item.rowData.email

  }</span>;
};
// const profileName = (item) => {
//   return <span>{item.rowData.first_name + item.rowData.last_name}</span>;
// };


const CreatedOnCell = ({ item }) => {
  const formatDate = (isoDate) => {
    return moment(isoDate).format('DD MMMM YYYY');
  };
  return <span>{formatDate(item)}</span>;
};
const Lastlogincell = ({ item }) => {
  const formatDate = (isoDate) => {
    return moment(isoDate).format('DD MMMM YYYY');
  };
  return <span>{formatDate(item)}</span>;
};
const cellMap = {
  name: Name,
  email: Email,
  created_on: CreatedOnCell,
  last_login:Lastlogincell,
  action: Action
};

const Invite = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.user);
  const { pagination, search, ordering, orderBy, filter, count, page_size, num_pages, page_number } = list;
 
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    paymentMode: []
  })

  

  useEffect(() => {
    let keysearch = "";
    // let keyfilter = "";
    if (search) {
      keysearch = `&search=${search}`;
    }
    // if (filter.params) {
    //   keyfilter = `&filter=${filter.params}`;
    // }
    dispatch(
      getUser(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );

  }, [dispatch, pagination?.count,
    pagination?.page_size,
    pagination?.page_number,
    search, 
    pagination?.num_pages,
    list.ordering
  ]);

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  

  const navigate = useNavigate()
  function onEdit(data) {
    //navigate(`/agencies/userinfo`)
    console.log("data",data.id)
    navigate(`/agencies/userinfo/${data?.id}`)

  }
  
  //console.log("Results",list?.results)
  return (
    <div className={className}>
      <Table
        title={"Agency User"}
        headers={list?.headers}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        // ordering={ordering}
        // orderBy={orderBy || []}
        // onSort={onSort}
        // filter={filters}
        // onFilter={onFilter}
        // selectedFilters={selectedFilters}
        // setSelectedFilters={setSelectedFilters}
        // filterOptions={filters}
        //applyFilter={applyFilter}
         onEdit={onEdit}
         isSearch={true}
        // isSort
        // isFilter
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

export default styled(Invite)(styles);
