const AddedByStyles = (theme) => ({

    width: '100%',
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    ".heading": {
        fontWeight: 400
    },
    ".image_container": {

    },
    ".added_by_comp_main": {
        display: "flex",
        alignItems: "center",
        gap: "25px",
    },
    ".details_conainer": {
        display: "flex",
        flexDirection: "column",
        gap: "2px"
    },
    ".name_container": {
        display: "flex",
        gap: "15px",
        alignItems: "center"
    }
})
export default AddedByStyles