import React, { useEffect } from 'react'
import {
    Button,
    Grid,
    styled,
  } from "@mui/material";
import Style from './style';
import Table from '../../components/Table';
import { Link,useNavigate } from 'react-router-dom';
import {ActionCell} from "../../components/MoreAction/MoreAction"
import axios from 'axios';

const UserManagement = (props) => {
const navigate = useNavigate()

  const featureTableHeader = [
    { field: "Name", label: "NAME" },
    { field: "EmailId", label: "Email Id" },
    { field: "PhoneNumber", label: "Phone Number" },
    { field: "UserGroup", label: "User Group" },
    { field: "action", label: "ACTION" },
  ];
  const sampleData = [
    {
        Name: "user1",
        EmailId: "abc@gmail.com",
        PhoneNumber: "(711) 990-4714",
        UserGroup: "Pro Users",
    },
    {
        Name: "user2",
        EmailId: "xyz@gmail.com",
        PhoneNumber: "665445646747",
        UserGroup: "Admin User Group",
    },
    {
        Name: "user3",
        EmailId: "abc@gmail.com",
        PhoneNumber: "(711) 990-4714",
        UserGroup: "Pro Users",
    },
  ];
 
    const {className} = props
    const SampleCell = ({ item }) => {
      return <span>{item}</span>;
    };
    
    const StatusCell = ({ item }) => {
      const color = item === "Completed" ? "green" : item === "Pending" ? "orange" : "red";
      return <span style={{ color }}>{item}</span>;
    };
    const cellMap = {
      productName: SampleCell,
      amount: SampleCell,
      paymentType: SampleCell,
      productStripeId: SampleCell,
      status: StatusCell, 
      action: ActionCell,
    };
    function onEdit(data){
      navigate(`/feature/${data?.productStripeId}/edit`)
    }
  return (
    <Grid className={className}>
      <Table
      title = {"User Management"}
      headers = {featureTableHeader}
      buttonText={"+ Create New User"}
      createUrl={"/userMangement/create"}
      data={sampleData}
      cellMap={cellMap}
      onEdit={onEdit}
      />
    </Grid>
  )
}

export default styled(UserManagement)(Style)