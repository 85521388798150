const styles = {
    root: {
        '& .MuiOutlinedInput-root': {
            height: 36,
            minHeight: 36,
            padding: '8px',
            borderRadius: '7px',
            '& fieldset': {
                borderRadius: '7px',
            },
        },
    },
    selectInput: {
        minWidth: '200px',
        '& .MuiOutlinedInput-root': {
            height: 36,
            minHeight: 36,
            padding: '8px',
            borderRadius: '7px',
            '& fieldset': {
                borderRadius: '7px',
            },
        },
    },
};

export default styles;
