const style = (theme) => ({
    resetpopup:{
        '& .css-hz1bth-MuiDialog-container ' :{
            '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper':{
                backgroundColor:"#ffffff !important"
            }
        }
    }
})

export default style;