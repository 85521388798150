import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import SwitchComponent from "../../../components/Switch";
import AddUser from "./AddUser";

const NewUserGroup = (props) => {
  const { className } = props;

  //topCard
  

  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
    >
      <Typography variant="h4" className="mb-2">New User Group    </Typography>
      <Grid item xs={12} className="userGroupContainer">

        {/* user group name */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User Role Group Name</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>Create a group of users to access the dashboard with which limits role of these users.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="text.labelText" className="pb-1">Group Name</Typography>
              <TextField
              fullWidth
              size="small"
              />
              </Grid>
              <Grid item xs={12} sm={7}>
              <Typography variant="body1" color="text.labelText" className="pb-1">Tags</Typography>
              <TextField
              fullWidth
              size="small"
              />
              </Grid>
              <Grid item xs={12} sm={7}>
              <Typography variant="body1" color="text.labelText" className="pb-1">Description</Typography>
              <TextField
              fullWidth
              size="small"
              />
              </Grid>
              <Grid item xs={12} sm={5} align="right" className="dflex aEnd jEnd">
                <Button variant="contained" color="secondary" className="mr-1" size="small">Reset</Button>
                <Button variant="contained" color="primary" size="small">Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* user group name */}
        <Divider sx={{ mt: 5, mb: 5, width:'100%' }} />
        {/* Manage user */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>Manage User</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>Add or delete users to this group from the users added to this application.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="text.labelText" className="pb-1">Add Users</Typography>
              <TextField
              fullWidth
              size="small"
              />
              </Grid>

              <Grid item xs={12}>
              <AddUser />
              </Grid>

              <Grid item xs={12} sm={12} align="right" className="dflex aEnd jEnd">
                <Button variant="contained" color="secondary" className="mr-1" size="small">Reset</Button>
                <Button variant="contained" color="primary" size="small">Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* user group name */}
        <Divider sx={{ mt: 5, mb: 5, width:'100%' }} />
        {/* user access */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User Access</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>Create a user access (limitation) for the users belongs to the group created by you</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} className="accessTable">
                <Grid container>
                  <Grid item xs={5} className="cell br-rt">
                    <Typography variant="body1" align="center">Menu</Typography>
                    <Typography variant="body2" color={"text.greyText5"} align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
                  </Grid>
                  <Grid item xs={7}>
                  <Typography variant="body1" align="center" className="cell br-bt">User Access</Typography>
                  <Grid container justifyContent={"space-around"} className="">
                    <Grid item className="cell"><Typography variant="body2" className="fw-500" color={"text.greyText1"}>All</Typography></Grid>
                    <Grid item className="cell"><Typography variant="body2" className="fw-500" color={"text.greyText1"}>View</Typography></Grid>
                    <Grid item className="cell"><Typography variant="body2" className="fw-500" color={"text.greyText1"}>Edit</Typography></Grid>
                    <Grid item className="cell"><Typography variant="body2" className="fw-500" color={"text.greyText1"}>Delete</Typography></Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12} className="emptySpace"></Grid>
                </Grid>

{/* Loop Section */}
                <Grid container>
{/* Loop item */}
                  <Grid item xs={5} className="cell br-rt br-bt">
                    <Typography variant="body1" className="fw-600" color={"text.greyText1"}>Agency Dashboard</Typography>
                  </Grid>
                  <Grid item xs={7}>
                  <Grid container justifyContent={"space-around"} className="br-bt switches">
                    <Grid item className="cell"><SwitchComponent /></Grid>
                    <Grid item className="cell"><SwitchComponent /></Grid>
                    <Grid item className="cell"><SwitchComponent /></Grid>
                    <Grid item className="cell"><SwitchComponent /></Grid>
                  </Grid>
                  </Grid>

                  {/* Sub Item */}
                  <Grid item xs={5} className="cell br-rt">
                    <Typography variant="body1" align="" className="fs-13 ml-2"  color={"text.greyText1"}>User List</Typography>
                  </Grid>
                  <Grid item xs={7}>
                  <Grid container justifyContent={"space-around"} className=" br-bt switches">
                    <Grid item className="cell"><SwitchComponent /></Grid>
                    <Grid item className="cell"><SwitchComponent /></Grid>
                    <Grid item className="cell"><SwitchComponent /></Grid>
                    <Grid item className="cell"><SwitchComponent /></Grid>
                  </Grid>
                  </Grid>
                  {/* Sub Item */}

{/* Loop item */}
                  <Grid item xs={12} className="emptySpace"></Grid>
                </Grid>
{/* Loop Section */}
              </Grid>
             
              <Grid item xs={12} align="right" className="dflex jEnd mt-4">
                <Button variant="contained" color="secondary" className="mr-1" size="small">Reset</Button>
                <Button variant="contained" color="primary" size="small">Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* user access */}

      </Grid>
</Grid>
  );
};

// default props
NewUserGroup.defaultProps = {
  classes: {}
};

// prop types
NewUserGroup.propTypes = {
  classes: PropTypes.object
};


export default styled(NewUserGroup)(Style);
