import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  styled,
} from "@mui/material";
import Style from './style';
import Table from '../../components/Table';
import { Link, useNavigate } from 'react-router-dom';
import { ActionCell } from "../../components/MoreAction/MoreAction";
import { getfeatureAsync, deleteFeatureByIdAsync } from "../../store/actions/featureAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setFeatureById
} from "../../store/reducers/featureReducer";
import { setActiveFilterHeader, setOpenFilter } from "../../store/reducers/uiReducer"
const Feature = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state) => state.feature);
  const { pagination, search } = list;

  const addonsTableHeader = [
    { field: "feature_name", label: "Feature NAME" },
    { field: "price", label: "AMOUNT" },
    { field: "status", label: "Status" },
    { field: "action", label: "ACTION" }
  ];

  const { className } = props;

  const FeatureNameCell = ({ item }) => {
    return (
     
        <span>{item}</span>
     
    );
  };

  const AmountCell = ({ item }) => {
   
    return <span style={{ fontWeight: "bold", color: "#2563EB" }}>₹{item}</span>;
  };

  const SampleCell = ({ item }) => <span>{item}</span>;

  const StatusCell = ({ item }) => {
    const color = Number(item) === 1 ? "#D4F6D2" : Number(item) === 0  ? "#FFDEDE" : "orange";
    const textColor = Number(item) === 1 ? "#0E8E2A" : Number(item) === 0  ? "#FF3B3B" : "orange";
    const width = "100px"; // Set a specific width for consistency
    const textContent = Number(item) === 1 ? "Active" : Number(item) === 0  ? "Paused" : "Disabled";
    return (
      <span
        style={{
          display: "inline-block",
          width: width,
          textAlign: "center",
          color: textColor,
          backgroundColor: color,
          borderRadius: "6px",
          padding: "8px 10px",
        }}
      >
        {textContent}
      </span>
    );
  };


  const cellMap = {
    feature_name: FeatureNameCell,
    price: AmountCell,
    productStripeId: SampleCell,
    status: StatusCell,
    action: ActionCell,
  };

  const onEdit = (data) => {
    navigate(`/products/feature/edit/${data?.id}`);
  };

  const onDelete = (data) => {
    dispatch(deleteFeatureByIdAsync(data?.id));
  };

  useEffect(() => {
    dispatch(getfeatureAsync({ ...pagination, search }));
  }, [dispatch, pagination.page_size, pagination.page_number, search]);

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };
  useEffect(() => {
    dispatch(setActiveFilterHeader(false))
    dispatch(setFeatureById())
    dispatch(setOpenFilter(false))
  }, [])

  return (
    <Grid className={className}>
      <Table
        title={"Feature"}
        headers={addonsTableHeader}
        buttonText={"+ Create Feature"}
        createUrl={"/products/feature/create"}
        data={list.results}
        cellMap={cellMap}
        onEdit={onEdit}
        onDelete={onDelete}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        isSearch={true}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </Grid>
  );
};

export default styled(Feature)(Style);
