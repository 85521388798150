import { z } from 'zod';

const createLicenceSchema = z.object({
  plan_name: z.string().min(1, "Licence Name is required"),
  plan_description: z
    .string()
    .min(1, "Description is required"),
  plan_type: z.string().min(1, "Plan Type is required"),
  plan_category: z.string().min(1, "Plan Category is required"),
  validity: z.string().min(1, "Validity is required"),
  plan_code: z.string().min(1, "Plan Code is required"),
  // showPlan: z.string().min(1, "Show Plan is required"),
  trial_period: z.string().min(1, "Trial Period is required"),
  url_code: z.string().optional(),
  price: z.string().min(1, "Price is required"),
  pre_offer_price: z.string().min(1, "Pre offer price"),
  extra_price: z.string().min(1, "Extra price"),
  payment_type: z.string().min(1, "Payment Type is required"),
  plan_period: z.string().min(1, "Plan Period is required"),
  user_count: z.string().min(1, "User count is needed"),
  client_user_count: z.string().min(1, "Client user couunt needed"),
  status:z.string().optional()
});


export default createLicenceSchema;
