const Style = () => ({
    height:'100vh',
    display:"flex",
    flexDirection:'column',
    gap:'10px',
    width:"100%",
  ".header": {
    width:"100%",
    display: 'flex',
    justifyContent:"space-between"
  },
   
  ".container": {
    display:'flex',
    width:"100%"
  },
  
})

export default Style;