/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select, MenuItem, styled } from '@mui/material';
import styles from './styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    ...styles.root,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    ...styles.selectInput,
}));

const CustomSelect = ({ id, label, value, onChange, options, defaultValue }) => {
    return (
        <StyledFormControl variant="outlined">
            <InputLabel>{label}</InputLabel>
            <StyledSelect
                labelId={`${id}-label`}
                id={id}
                value={value || ''}
                onChange={onChange}
                label={label}
                defaultValue={defaultValue}
            >
                {options?.map((option) => (
                    <MenuItem key={option.value} value={option.value} defaultValue={defaultValue}>
                        {option.label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
};

CustomSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultValue: PropTypes.string,
};

export default CustomSelect;
