import theme from "../../../theme";

const Style = () => ({
    '.cardContainer': {
        borderRadius: '12px',
        background: theme.palette.containers.card,
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
        padding: '16px'
    },
    '.users': {
        '& .MuiAvatar-root, & img': {
            height: '32px',
            width: '32px',
            fontSize: '14px'
        }
    },
    '.tags': {
        '& .MuiChip-root': {
            borderRadius: '6px',
            height: '26px',
            border: `1px solid ${theme.palette.grey.border}`,
            color: theme.palette.text.greyText1,
            background: 'transparent',
            fontSize: '12px'
        }
    },
    '.userImg': {
        height: '64px',
        width: '64px',
        borderRadius: '8px'
    }
})

export default Style;