import { createAsyncThunk } from "@reduxjs/toolkit";
import { agenciesService } from "../services/agenciesService";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const getAgencies = createAsyncThunk(
  "agencies/getAgencies",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      
      dispatch(setLoading("Fetching Agencies..."));
      const response = await agenciesService.getAgencies(data);
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);


export const getAgenciesById = createAsyncThunk("agencies/byId",
  async (param, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.getAgencyById(param);
      dispatch(setLoading(null));

      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const getAgencyDeatil = createAsyncThunk("agencies/agencyClient",
  async (param, { rejectWithValue, dispatch }) => {
    const { id, detail, stripe_id,subscription_id,data } = param
   
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.getAgencyDeatil(id, detail,stripe_id,subscription_id, data);
  
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)
export const getAllAgencyDetailsAsync = createAsyncThunk("agencies/getAllAgencyList",
  async ( param , { rejectWithValue, dispatch }) => {
    
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.getAllAgencyDetail(param);
  
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)