import React, { useEffect, useState } from "react";
import { styled, Avatar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getAgencies } from "../../store/actions/agenciesAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  applyFilter,
  clearFilter,
} from "../../store/reducers/agenciesReducer";
import Table from "../../components/Table";
import { rulesToParams } from "../../components/Filter/filterUtil";
import moment from 'moment';
import { ActionCell } from "../../components/MoreAction/MoreAction";
import { useNavigate } from "react-router-dom"
const styles = {
  ".avatar-cell": {
    display: "flex",
    alignItems: "center",
    ".avatar-title": {
      marginLeft: "8px",
    },
  },
};

const ProfileCell = (props) => {
  const { item } = props;
  const { username, avatar_filename } = item || {};
  return (
    <div className="avatar-cell">
      <Avatar alt={username} src={avatar_filename} />
      <span className="avatar-title">{username}</span>
    </div>
  );
};
const agencyName = (item) => {

  return <span>{item.rowData.agency_name
  }</span>;
};
const agencyEmail = (item) => {

  return <span>{item.rowData.agency_email

  }</span>;
};
const profileName = (item) => {
  return <span>{item.rowData.first_name + item.rowData.last_name}</span>;
};
const StatusCell = (props) => {
  const { item, rowData } = props
  const color = rowData?.newStatus === "Trial" ? "#2563EB20" : rowData?.newStatus === "Active" ? "#D4F6D2" : "#FFDEDE"
  const textColor = rowData?.newStatus === "Trial" ? "blue" : rowData?.newStatus === "Active" ? "#0E8E2A" : "#FF3B3B"
  const width = "100px"; // Set a specific width for consistency
  const renderText = rowData?.newStatus === "Trial" ? "Trail" : rowData?.newStatus === "Active" ? "Active" : "Inactive"
  return (
    <span
      style={{
        display: "inline-block",
        width: width,
        textAlign: "center",
        color: textColor,
        backgroundColor: color,
        borderRadius: "6px",
        padding: "8px 10px",
      }}
    >
      {rowData?.newStatus}
    </span>
  );
};
const CreatedOnCell = ({ item }) => {
  const formatDate = (isoDate) => {
    return moment(isoDate).format('DD MMMM YYYY');
  };
  return <span>{formatDate(item)}</span>;
};
const cellMap = {
  name: agencyName,
  status: StatusCell,
  email: agencyEmail,
  // profile_name: profileName,
  created_on: CreatedOnCell,
  action: ActionCell
};

const AgencyList = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.agencies);
  const { pagination, search, ordering, orderBy, filter, count, page_size, num_pages, page_number } = list;
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    paymentMode: []
  })

  const filters = [
    { key: "status", label: "Status", options: [{ value: '0', label: "Paused" }, { value: '1', label: "Active" }], },
    { key: "trial", label: "Trial", options: [{ value: '0', label: "Paused" }, { value: '1', label: "Trial" }], },
    {
      key:"agencyPlan" , label:"Plan",options:[
        { value: 'solopreneurPlan', label: 'Solopreneur Plan' },
        { value: 'educatePlan', label: 'Educate Plan' },
        { value: 'professionalPlan', label: 'Professional Plan' },
        { value: 'premium', label: 'Premium' },
        { value: 'standard', label: 'Standard' },
      ]
    }
  
  ];

  useEffect(() => {
    let keysearch = "";
    let keyfilter = "";
    if (search) {
      keysearch = `&search=${search}`;
    }
    if (filter.params) {
      keyfilter = `&filter=${filter.params}`;
    }
    dispatch(
      getAgencies(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );

  }, [dispatch, pagination.count,
    pagination?.page_size,
    pagination?.page_number,
    search, ordering,
    filter.params,
    pagination?.num_pages,
    list.ordering
  ]);

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  const onFilter = (reason, data) => {
    switch (reason) {
      case "add":
        dispatch(addFilterRule());
        break;
      case "remove":
        dispatch(removeFilterRule(data));
        break;
      case "update":
        dispatch(updateFilterRule(data));
        break;
      case "apply":
        dispatch(applyFilter(rulesToParams(filter.rules)));
        break;
      case "clear":
        dispatch(clearFilter());
        break;
      default:
        return;
    }
  };

  const applyFilter = () => {
    dispatch(
      getAgencies(
        { ...pagination, search, ...selectedFilters, ordering: list.ordering }
      )
    );
  }
  const navigate = useNavigate()
  function onEdit(data) {
    navigate(`/agencies/${data?.id}`)

  }
  return (
    <div className={className}>
      <Table
        title={"Agencies List"}
        headers={list?.headers}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        filter={filters}
        onFilter={onFilter}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterOptions={filters}
        applyFilter={applyFilter}
        onEdit={onEdit}
        isSort
        isFilter
        isSearch={true}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

export default styled(AgencyList)(styles);
