import React from "react";
import { Button, Grid, IconButton, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Text from "../Text";

const Rule = (props) => {
  const { item, headers, onFilter } = props;
  const onChange = (field, val) => {
    onFilter("update", {
      ...item,
      [field]: val,
    });
  };

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "8px",
      }}
    >
      <Grid style={{ flex: 1 }}>
        <Text
          value={item?.field || ""}
          size="small"
          placeholder="column"
          select
          fullWidth
          onChange={(val) => onChange("field", val)}
        >
          {headers.map((m, i) => {
            return (
              <MenuItem
                key={`rule_single_${item.field}_${item.operator}_${i}`}
                value={m.filter_field}
              >
                {m.label}
              </MenuItem>
            );
          })}
        </Text>
      </Grid>
      <Grid style={{ flex: 1, marginLeft: "4px" }}>
        <Text
          fullWidth
          size="small"
          placeholder="operator"
          select
          value={item.operator || ""}
          onChange={(val) => onChange("operator", val)}
          name={"operator"}
        >
          <MenuItem value="contains">Contains</MenuItem>
          <MenuItem value="startswith">Starts with</MenuItem>
          <MenuItem value="equals">Equals</MenuItem>
          <MenuItem value="regex">Regex</MenuItem>
        </Text>
      </Grid>
      <Grid style={{ flex: 1, marginLeft: "4px" }}>
        <Text
          fullWidth
          size="small"
          placeholder="Enter Search..."
          value={item.value || ""}
          onChange={(val) => onChange("value", val)}
        />
      </Grid>
      <Grid style={{ marginLeft: "8px" }}>
        <IconButton onClick={() => onFilter("remove", item.index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const Rules = (props) => {
  const { headers, rules, onFilter } = props;

  return (
    <Grid>
      {rules.map((m, i) => {
        return (
          <Rule
            headers={headers}
            key={`rule_${i}`}
            item={m}
            onFilter={onFilter}
          />
        );
      })}
      <Grid
        style={{
          display: "flex",
          marginTop: "12px",
          justifyContent: "space-between",
        }}
      >
        <Grid style={{ display: "flex" }}>
          <Button onClick={() => onFilter("add")}>Add Rule</Button>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid style={{ display: "flex" }}>
            <Button onClick={() => onFilter("clear")}>Clear Filter</Button>
          </Grid>
          <Grid style={{ display: "flex" }}>
            <Button onClick={() => onFilter("apply")}>Apply Filter</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Rules;
