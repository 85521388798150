import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import AgencyAddons from "../containers/AgencyAddons";
import UserManagementForm from "../containers/userManagement/UserManagementForm/userManagementForm";
import UserManagement from "../containers/userManagement";


const userManagementRoutes = [
    {
        path:"/userMangement",
        element:<ProtectedRoute/>,
        children:[{
            path:'',
            element:<UserManagement/>
        },
        {
            path: "create",
            element: <UserManagementForm />
        }
    ]
    }
]

export default  userManagementRoutes