import { z } from 'zod';

const cretaeAddonsSchema = z.object({
    name: z.string().min(1, "Product Name is required"),
    description: z.string()
        .min(1, "Description is required")
        .min(5, "Description must be at least 5 characters long")
        .max(80, "Description must be at most 80 characters long"),
    comments: z.string()
        .min(1, "Comments are required").optional(),
    addon_types: z.string().min(1, "Addon Type is required"),
    featureStatus: z.string().min(1, "Product Status is required"),
    price: z.string().min(1, "Current Price is required"),
    title: z.string().min(1, "Title  is required").optional(),
    plan_label: z.string().min(1, "Label  is required").optional(),
    start_unit_amount: z.string().default(0).optional(),
    upto_count_start: z.string().default('').optional(),
    end_unit_amount: z.string().default(0).optional(),
    upto_count_end: z.string().default('').optional(),
    billing_cycle: z.string().min(1, "Billing cycle is required"),
    plan_code: z.string().min(1, "code  is required")
});

export default cretaeAddonsSchema;
