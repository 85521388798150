import { oldVersionPostRequest, oldVersionGetRequest, oldVersionDeleteRequest, oldVersionUpdateRequest } from "../../utils/apiHelper";


async function getAllAddons(data) {
    return oldVersionPostRequest('featureFlag/getAll', data)
}
async function createAgencyAddons(data) {
    return oldVersionPostRequest('featureFlag/create', data)
}
async function updateAgencyAddons(param,data) {
    return oldVersionUpdateRequest(`featureFlag/${param}`, data)
}
async function deleteAgencyAddons(param,data) {
    return oldVersionDeleteRequest(`featureFlag/${param}`)
}
export const agencyAddonsService = {
    getAllAddons,
    createAgencyAddons,
    updateAgencyAddons,
    deleteAgencyAddons
}