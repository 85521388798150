import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import AgencyAddons from "../containers/AgencyAddons";

const agencyAddonsRoutes = [
    {
        path:"/flags",
        element:<ProtectedRoute/>,
        children:[{
            path:'',
            element:<AgencyAddons/>
        }]
    }
]

export default  agencyAddonsRoutes