import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";

const CountCardComponent = (props) => {
  const { className, data, xs, sm, md } = props;

  return (
    <>
      {data.map((item) => (
        <Grid item xs={xs} sm={sm} md={md}>
          <Grid
            container
            alignItems={"center"}
            flexWrap={"nowrap"}
            className={className}
          >
            <Grid item className="iconContaner">
              {item.icon}
            </Grid>
            <Grid className="contentContainer">
              <Typography
                variant="body2"
                color={"text.greyText1"}
                sx={{ pb: 1 }}
              >
                {item.name}
              </Typography>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" color={"text.primaryText"}>
                  {item.count}
                </Typography>
                <Typography variant="body1" sx={{ paddingLeft: '8px' }} color={item.percentage > 0 ? "colors.green" : "colors.pink"}>
                  {item.percentage}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

// default props
CountCardComponent.defaultProps = {
  data: [],
  xs: 12,
  sm: 6,
  md: 3,
};

// prop types
CountCardComponent.propTypes = {
  data: PropTypes.array,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
};

// export default styled(CountCard)(Style);
const CountCard = styled(CountCardComponent)(({ theme }) => Style(theme));

export default CountCard;
