import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { styled, TextField as Core, MenuItem } from '@mui/material';

const TextFieldComponent = forwardRef(({
    id = "",
    name = "",
    placeholder,
    autoFocus,
    autoComplete = "new-password",
    margin = "dense",
    min = "0",
    variant = "outlined",
    fullWidth = true,
    withImage = false,
    passShow = false,
    select = false, // Add a prop to specify if it's a select
    options = [],
    // Add a prop for options if it's a select
    defaultValue,
    ...rest
}, ref) => {

    return (
        <Core
            id={id}
            placeholder={placeholder}
            name={name}
            autoComplete={autoComplete}
            margin={margin}
            variant={variant}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            inputRef={ref}
            select={select}
            defaultValue={defaultValue}

            // Enable select functionality
            {...rest}

        >
            {select && options.map(option => (
                <MenuItem key={option.value} value={option.value} defaultValue={defaultValue}>
                    {option.label}
                </MenuItem>
            ))}
        </Core>
    );
});

// Apply styling
const CustomInput = styled(TextFieldComponent)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        height: '42px',
        minHeight: '42px',
        padding: '8px',
        borderRadius: '7px',
        '& fieldset': {
            borderRadius: '7px',
        },
    },
}));

export default CustomInput;

// Update PropTypes to include new props
TextFieldComponent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    min: PropTypes.string,
    autoComplete: PropTypes.string,
    margin: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    withImage: PropTypes.bool,
    passShow: PropTypes.bool,
    select: PropTypes.bool, // New prop for select functionality
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ), // New prop for options if it's a select
    defaultValue :PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
       

    ]),
    onChange: PropTypes.func,
};
