import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchService } from "../services/searchService"; 
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getSearch = createAsyncThunk(
    "search/searchAgencies",
    async (data, { rejectWithValue, dispatch }) => {
      try {
       
        dispatch(setLoading("Fetching Agencies..."));
        const response = await searchService.getSearchdatas(data);
        
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );