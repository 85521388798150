import theme from "../../theme";

const Style = () => ({
    '.userGroupList': {
        padding: '24px',
        borderRadius: '12px',
        background: theme.palette.containers.card,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.06)',
    }
})

export default Style;