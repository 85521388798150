import { createSlice } from "@reduxjs/toolkit";
import { getLicenseAsync, deleteLicenseByIdAsync, createlicenseAsync, updateLicenseAsync, getLicenseByIdAsync } from "../actions/licenseAction"
const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 0,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            params: "",
            rules: [{ index: 0, field: "", operator: "", value: "" }],
        },
        ordering: "license_name",
        orderBy: [{ field: "license_name", label: "ASC: License Name" }],
    },
    licenseById: {},
    loading: false,
    licenseId: 0
};

const licenseSlice = createSlice({
    name: "license",
    initialState,
    reducers: {
        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setLicenseId(state, action) {
            state.licenseId = action.payload
        },
        setlicenseById(state, action) {
            state.licenseById = {}
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(createlicenseAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(createlicenseAsync.fulfilled, (state, { payload }) => {
                state.feature = payload;
                state.loading = false;
            })
            .addCase(createlicenseAsync.rejected, (state) => {
                state.loading = false;
            })
        builder
            .addCase(getLicenseAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLicenseAsync.fulfilled, (state, { payload }) => {
                state.list.results = payload.results;
                state.list.pagination = {
                    ...state.list.pagination,
                    ...payload.pagination
                }
                state.loading = false;
            })
            .addCase(getLicenseAsync.rejected, (state) => {
                state.loading = false;
            })
        builder
            .addCase(getLicenseByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(getLicenseByIdAsync.fulfilled, (state, { payload }) => {
                state.licenseById = {
                    ...payload
                }
                state.loading = false;
            })
            .addCase(getLicenseByIdAsync.rejected, (state) => {
                state.loading = false
            })
        builder
            .addCase(deleteLicenseByIdAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteLicenseByIdAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(deleteLicenseByIdAsync.rejected, (state) => {
                state.loading = false;
            })
        builder
            .addCase(updateLicenseAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateLicenseAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(updateLicenseAsync.rejected, (state) => {
                state.loading = false;
            });



    }
});

export const {
    setPageSize,
    setPage,
    setSearch,
    setLicenseId,
    setlicenseById
} = licenseSlice.actions
export default licenseSlice.reducer;