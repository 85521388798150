import { createAsyncThunk } from "@reduxjs/toolkit";
import {agencyAddonsService} from "../services/agencyAddonsService"
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const getAddonsAsync = createAsyncThunk("agencyAddons/getAll", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Fetching Agencey Addons..."))
        const response = await agencyAddonsService.getAllAddons(data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
export const createAgencyAddonsAsync = createAsyncThunk("agencyAddons/create", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Creating Agencey Feature..."))
        const response = await agencyAddonsService.createAgencyAddons(data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const updateAgencyAddonsAsync = createAsyncThunk("agencyAddons/update", async ({id,data}, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("updating feature flag..."))
        const response = await agencyAddonsService.updateAgencyAddons(id,data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const deleteAgencyAddonsAsync = createAsyncThunk("agencyAddons/delete", async (id, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("updating feature flag..."))
  
        const response = await agencyAddonsService.deleteAgencyAddons(id);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})
