import React, { useState } from 'react';
import { Button, Typography, Grid } from "@mui/material";
import Modal from '../../../../components/Modal';
import { UpdateIcon } from "../../../../assets/svg/index";
import AsyncAutocomplete from "../../../../components/AutoCompleteText/index";
import { useSelector, useDispatch } from 'react-redux';
import SwitchComponent from "../../../../components/Switch/index";
import { updateAgencyAddonsAsync } from "../../../../store/actions/agencyAddonsAction";

const UpdateAgencyAddons = (props) => {
  const { list } = useSelector((state) => state.agencies);
  const dispatch = useDispatch();
  const { rowData } = props;
  const [featureSearch, setFeatureSearch] = useState(rowData?.flag_name);
  const [isEnabled, setIsEnabled] = useState(rowData?.is_enabled);
  const [open, setOpen] = useState(false);

  const popUpHandler = () => {
    setOpen(!open);
  };

  const agencyDefaultValue = {
    label: rowData?.agency_name,
    value: rowData?.agency_id
  };

  const featureDefaultValue = {
    label: rowData?.flag_name,
    value: rowData?.feature_id // Assuming there's a feature_id in rowData
  };

  const agencyOptions = list?.agencyDetails?.agencyList?.map((data) => {
    return {
      label: data.agency_name,
      value: data.id
    };
  });

  const featureOptions = list?.agencyDetails?.featureList?.map((data) => {
    return {
      label: data.feature_name,
      value: data.id
    };
  });

  const featureOnChange = (selectedOption) => {
    setFeatureSearch(selectedOption.label);
  };

  const updateFeatureFlag = () => {
    dispatch(updateAgencyAddonsAsync({
      id: rowData?.id,
      data: {
        flag_name: featureSearch,
        is_enabled: isEnabled
      }
    }));
  };

  return (
    <div>
      <div onClick={popUpHandler} style={{ cursor: "pointer" }}>
        <UpdateIcon />
      </div>
      <Modal
        open={open}
        handleClose={popUpHandler}
        title="Update Agency Feature Flag"
        actions={
          <>
            <Button onClick={popUpHandler}  color="secondary" variant='outlined'>
              Cancel
            </Button>
            <Button onClick={updateFeatureFlag} color="primary" variant='contained'>
              Update
            </Button>
          </>
        }
      >
        <Grid container spacing={2} direction="column">
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">Agencie's Name</Typography>
              <AsyncAutocomplete
                defaultValue={agencyDefaultValue}
                inputOptions={agencyOptions}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">Feature Name</Typography>
              <AsyncAutocomplete
                inputOptions={featureOptions}
                defaultValue={featureDefaultValue}
                onChange={featureOnChange}
              />
            </Grid>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center", paddingLeft: "16px" }}>
            <Typography variant="h5" style={{ marginRight: "16px" }}>Flag Enable</Typography>
            <SwitchComponent checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default UpdateAgencyAddons;
