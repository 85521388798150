import { createSlice } from "@reduxjs/toolkit";
import { getSearch } from "../actions/searchAction"; 

const initialState = {
    agencies: {
        data: [],
        totalPages: 0,
    },
    users: {
        data: [],
        totalPages: 0,
    },
    invoices: {
        data: [],
        totalPages: 0,
    },
    
    loading: null,
    
};

export const searchSlice =createSlice({
    name:"search",
    initialState,
    reducers: {
        searchResults(state) {
            state.agencies = {
                data: [],
                totalPages: 0,
            };
            state.users = {
                data: [],
                totalPages: 0,
            };
            state.invoices = {
                data: [],
                totalPages: 0,
            };
            
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSearch.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSearch.fulfilled, (state, action) => {
                state.agencies.data = action.payload?.data?.agencies;
                state.users.data = action.payload?.data?.users;
                state.invoices.data = action.payload?.data?.invoice;
               
                state.loading = false;
                state.error = null;
            })
            .addCase(getSearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch data";
                state.agencies.data = [];
                state.users.data = [];
                state.invoices.data = [];
               
            })


    },
})

export const {searchResults}=searchSlice.actions;
export default searchSlice.reducer;