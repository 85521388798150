import React from 'react'
import { Grid, styled } from '@mui/material'
import PropTypes from "prop-types";
import FormHeaderStyles from './formHeaderStyles'
import CustomButton from "../../../../components/Button/ButtonComp"
import { useLocation } from 'react-router-dom';
const FormHeader = (props) => {
  const { title, className, cancelAction, saveAction, formType, error } = props
  const location = useLocation()


  const handleSave = () => {
    if (location.pathname.includes('edit')) {
      saveAction('update');
    } else {
      saveAction('create');
    }
  };

  return (
    <Grid className={`${className} header`} style={{ display: "flex", justifyContent: "space-between" }}>
      <h2 className="th-title">{title}</h2>
      <Grid className={'button_container'}>
        <CustomButton variant='outlined'
          onClick={cancelAction}
        >
          Cancel
        </CustomButton>
        <CustomButton variant='contained'
          onClick={handleSave}
          type='submit'
          disabled={error?.length > 0}
        >
          {
            location.pathname.includes('edit') ? "Update" : "Create"
          }

        </CustomButton>
      </Grid>

    </Grid>
  )
}
FormHeader.defaultProps = {
  title: "",
  cancelAction: () => { },
  saveAction: () => { }
}
FormHeader.propTypes = {
  title: PropTypes.string,
  cancelAction: PropTypes.func,
  saveAction: PropTypes.func
}
export default styled(FormHeader)(FormHeaderStyles)