import { createSlice } from "@reduxjs/toolkit";
import { createAddonsAsync, getAddonsAsync,getAddonsByIdAsync, deleteAddonsByIdAsync, updateAddonsByIdAsync } from "../actions/addonsAction";

const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            params: "",
            rules: [{ index: 0, field: "", operator: "", value: "" }],
        },
        ordering: "name",
        orderBy: [{ field: "name", label: "ASC: Name" }],
    },
    featureById: {},
    loading: null,
};

const addonSlice = createSlice({
    name: "feature",
    initialState,
    reducers: {

        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setFeatureById(state, action) {
            state.featureById = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAddonsAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAddonsAsync.fulfilled, (state, { payload }) => {
                state.feature = payload;
                state.loading = false;
            })
            .addCase(createAddonsAsync.rejected, (state) => {
                state.loading = false;
            })
        // getting all features
        builder
            .addCase(getAddonsAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(getAddonsAsync.fulfilled, (state, { payload }) => {
                
                state.list.results = payload.results
                state.list.pagination = { ...state.list.pagination, ...payload.pagination }

                state.loading = false;
            })
            .addCase(getAddonsAsync.rejected, (state) => {
                state.loading = false
            })
        // get feature by id
        builder
            .addCase(getAddonsByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(getAddonsByIdAsync.fulfilled, (state, { payload }) => {
                state.featureById = {
                    ...payload
                }
                state.loading = false;
            })
            .addCase(getAddonsByIdAsync.rejected, (state) => {
                state.loading = false
            })
        // delete feature by id
        builder
            .addCase(deleteAddonsByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteAddonsByIdAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(deleteAddonsByIdAsync.rejected, (state) => {
                state.loading = false
            })
        builder
            .addCase(updateAddonsByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(updateAddonsByIdAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(updateAddonsByIdAsync.rejected, (state) => {
                state.loading = false
            })


    }
});
export const {
    setPageSize,
    setPage,
    setSearch,
    setFeatureById
} = addonSlice.actions
export default addonSlice.reducer;
