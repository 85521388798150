import { oldVersionPostRequest, oldVersionGetRequest, oldVersionDeleteRequest, oldVersionUpdateRequest } from "../../utils/apiHelper";


async function cancelSubscriptions(data){
    return oldVersionPostRequest(`subscription/cancel`,data)
}
async function refundSubscription(data) {
    return oldVersionPostRequest(`subscription/refund`, data);
}
export const subscriptionService = {
    cancelSubscriptions,
    refundSubscription
}