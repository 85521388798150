import {
  MenuHome,
  MenuAgency,
  MenuSales,
  MenuEmail,
  MenuUser,
  MenuSttings,
  LicenseDashboard,
  FeatureDashboard,
  AgencyListIcon,
  Userslogo,
  FeatureIcon,
  FeatureFlag,
  UserMangment
} from "../../assets/svg/index";


export const MenuDatas = [
  {
    icon: <MenuHome />,
    name: "Home",
    link: "/home",
  },
  {
    icon: <MenuAgency />,
    name: "Agency Dashboard",
    link: "/agencies",
    permission: (p) => Object.values(p?.agencies || []).indexOf(true) >= 0,
    children: [
      {
        icon: <AgencyListIcon/>,
        name: "Agencies List",
        link: "/agencies/list",
        permission: (p) => p?.agencies?.agencies_list === true,
      },
      {
        icon: <Userslogo/>,
        name: "Users",
        link: "/agencies/users",
        permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon: <MenuUser />,
        name: "Invitations",
        link: "/invite",
       // permission: (p) => p?.agencies?.users_list === true,
      },
      // {
      //   icon: <MenuUser />,
      //   name: "Users",
      //   link: "/agencies/users",
      //   permission: (p) => p?.agencies?.users_list === true,
      // },
    ],
  },
  // {
  //   icon: <MenuSales />,
  //   name: "Sales Dashboard",
  //   link: "/sales",
  //   permission: (p) => Object.values(p?.sales || []).indexOf(true) >= 0,
  //   children: [
  //     {
  //       icon: <MenuUser />,
  //       name: "Customer payments",
  //       link: "/sales/customer",
  //       permission: (p) => p?.sales?.payment_list === true,
  //     },
  //     {
  //       icon: <MenuUser />,
  //       name: "Reports",
  //       link: "/sales/reports",
  //       permission: (p) => p?.sales?.reports_list === true,
  //     },
  //   ],
  // },
  // {
  //   icon: <MenuEmail />,
  //   name: "Email Campaign",
  //   link: "emailCampaign",
  // },
  // {
  //   icon: <MenuUser />,
  //   name: "User Management",
  //   link: "/users",
  //   permission: (p) => Object.values(p?.sales_users || []).indexOf(true) >= 0,
  //   children: [
  //     {
  //       icon: <MenuUser />,
  //       name: "Users Group",
  //       link: "usergroup",
  //       permission: (p) => p?.sales_users?.group_list === true,
  //     },
  //   ],
  // },

  {
    icon: <MenuAgency />,
    name: "Product",
    link: "/products",
    permission: (p) => Object.values(p?.agencies || []).indexOf(true) >= 0,
    children: [
      {
        icon: <LicenseDashboard/>,
        name: "Licence",
        link: '/products/licence',
        permission: (p) => p?.agencies?.agencies_list === true,
      },
      {
        icon: <FeatureDashboard />,
        name: "Addons",
        link: '/products/addons',
        permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon:<FeatureIcon/>,
        name: "Feature",
        link: '/products/feature',
       // permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon:<FeatureFlag/>,
        name: "Feature Flags",
        link: '/flags',
        permission: (p) => p?.agencies?.users_list === true,
      },
    ],
  },
  {
    icon:<UserMangment />,
    name: "User Management",
    link: '/userMangement'
  },

  // {
  //   icon: <FeatureDashboard />,
  //   name: "Invoices",
  //   link: '/invoices'
  // },
  // {
  //   icon: <MenuSttings />,
  //   name: "Settings",
  //   link: "/settings",
  // },
];

