import { oldVersionGetRequest, oldVersionPostRequest as postRequest } from "../../utils/apiHelper";

export const userService = {
    getUserdatas,
    getUserinfo,
    passwordreset
};


async function getUserdatas(data) {
  
    return await postRequest(`user/getAll`, data);
  }

  async function getUserinfo(data) {
     
    return await postRequest(`user/getuserinfo`, data);
  }
  async function passwordreset(data) {
     
    return await postRequest(`user/reset`, data);
  }