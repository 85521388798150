import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAgencyDetailsAsync } from "../../store/actions/agenciesAction";

export default function AsyncAutocomplete({ callback, inputOptions, onChange, defaultValue,disabled }) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.ui);

  let idleTime = null;

  function fetchFun(inp) {
    if (inp && callback) {
      clearTimeout(idleTime);
      idleTime = setTimeout(() => dispatch(callback(inp)), 1000);
    }
  }

  useEffect(() => {
    fetchFun(inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
      id="async-autocomplete"
      sx={{ width: 270, height: 80 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, value) => setInputValue(value)}
      onChange={(event, newValue) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      getOptionLabel={(option) => option.label}
      options={inputOptions}
      loading={loading}
      defaultValue={defaultValue}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
