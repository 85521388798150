import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  styled,
} from "@mui/material";
import Style from './style';
import Table from '../../components/Table';
import { Link, useNavigate } from 'react-router-dom';
import { ActionCell } from "../../components/MoreAction/MoreAction";
import { getAddonsAsync, deleteAddonsByIdAsync } from "../../store/actions/addonsAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setFeatureById
} from "../../store/reducers/featureReducer";
import { setActiveFilterHeader, setOpenFilter } from "../../store/reducers/uiReducer"
const Addons = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, list } = useSelector((state) => state.addons);
  const { pagination, search } = list;

  const addonsTableHeader = [
    { field: "name", label: "Addons NAME" },
    { field: "price", label: "AMOUNT" },
    { field: "title", label: "TITLE" },
    { field: "addon_type", label: "ADDON TYPE" },
    { field: "action", label: "ACTION" }
  ];

  const { className } = props;

  const FeatureNameCell = ({ item }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "200px", alignItems: "center" }}>
        <span>{item}</span>
      </div>
    );
  };

  const AmountCell = ({ item }) => {
   
    return <span style={{ fontWeight: "bold", color: "#2563EB" }}>₹{item}</span>;
  };

  const SampleCell = ({ item }) => <span>{item}</span>;

  const StatusCell = ({ item }) => {
    const color = item === "active" ? "#D4F6D2" : item === "paused" ? "#FFDEDE" : "orange";
    const textColor = item === "active" ? "#0E8E2A" : item === "paused" ? "#FF3B3B" : "orange";
    const width = "100px"; // Set a specific width for consistency

    return (
      <span
        style={{
          display: "inline-block",
          width: width,
          textAlign: "center",
          color: textColor,
          backgroundColor: color,
          borderRadius: "6px",
          padding: "8px 10px",
        }}
      >
        {item}
      </span>
    );
  };


  const cellMap = {
    feature_name: FeatureNameCell,
    price: AmountCell,
    productStripeId: SampleCell,
    status: StatusCell,
    addon_type:SampleCell,
    action: ActionCell,
  };

  const onEdit = (data) => {
    navigate(`/products/addons/edit/${data?.id}`);
  };

  const onDelete = (data) => {
    dispatch(deleteAddonsByIdAsync(data?.id));
  };

  useEffect(() => {
    dispatch(getAddonsAsync({ ...pagination, search }));
  }, [dispatch, pagination.page_size, pagination.page_number, search]);

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };
  useEffect(() => {
    dispatch(setActiveFilterHeader(false))
    dispatch(setFeatureById())
    dispatch(setOpenFilter(false))
  }, [])

  return (
    <Grid className={className}>
      <Table
        title={"Addons"}
        headers={addonsTableHeader}
        buttonText={"+ Create Addons"}
        createUrl={"/products/addons/create"}
        data={list.results}
        cellMap={cellMap}
        onEdit={onEdit}
        onDelete={onDelete}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}

      />
      <div>{loading ? "Loading..." : ""}</div>
    </Grid>
  );
};

export default styled(Addons)(Style);
