const styles = (theme) => ({
    width: '85px',
    height: '85px',
    position: 'relative',
    ".main": {
        width: '85px',
        height: '85px',
        borderRadius: '50%',
        overflow: 'hidden',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        zIndex: 0,
    },
    '.fileInput': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
        zIndex: 1,
    },
    '.logoTextPrev': {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
        },
    },
    '.noLogo': {
        fontSize: '14px',
        color: '#aaa',
    },
    '.profileEditContainer': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2563EB',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        zIndex: 2,
    },
    '.profileEditIcon': {
        color: '#fff',
    },
});

export default styles;
