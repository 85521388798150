import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../services/userService";  
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getUser = createAsyncThunk(
    "user/userAgencies",
    async (data, { rejectWithValue, dispatch }) => {
      try {
       
        dispatch(setLoading("Fetching Agencies..."));
        const response = await userService.getUserdatas(data);
        
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );


  export const getuserinfo = createAsyncThunk("user/userinfo",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        //console.log("id",param)
        dispatch(setLoading("Fetching Agency..."));
        const response = await userService.getUserinfo(param);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const passwordreset = createAsyncThunk("user/passwordreset",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        //console.log("id",param)
        dispatch(setLoading("Fetching Agency..."));
        const response = await userService.passwordreset(param);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )