import { createAsyncThunk } from "@reduxjs/toolkit";
import { inviteService } from "../services/inviteService"; 
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getInvite = createAsyncThunk(
    "invite/inviteAgencies",
    async (data, { rejectWithValue, dispatch }) => {
      try {
       
        dispatch(setLoading("Fetching Agencies..."));
        const response = await inviteService.getInvitedatas(data);
        
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );