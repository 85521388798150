import { createSlice } from "@reduxjs/toolkit";
import { createfeatureAsync, getfeatureAsync, getFeatureByIdAsync, deleteFeatureByIdAsync, updateFeatureByIdAsync } from "../actions/featureAction";

const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            params: "",
            rules: [{ index: 0, field: "", operator: "", value: "" }],
        },
        ordering: "feature_name",
        orderBy: [{ field: "feature_name", label: "ASC: Feature Name" }],
    },
    featureById: {},
    loading: null,
};

const featureSlice = createSlice({
    name: "feature",
    initialState,
    reducers: {

        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setFeatureById(state, action) {
            state.featureById = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createfeatureAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(createfeatureAsync.fulfilled, (state, { payload }) => {
                state.feature = payload;
                state.loading = false;
            })
            .addCase(createfeatureAsync.rejected, (state) => {
                state.loading = false;
            })
        // getting all features
        builder
            .addCase(getfeatureAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(getfeatureAsync.fulfilled, (state, { payload }) => {
                
                state.list.results = payload.results
                state.list.pagination = { ...state.list.pagination, ...payload.pagination }

                state.loading = false;
            })
            .addCase(getfeatureAsync.rejected, (state) => {
                state.loading = false
            })
        // get feature by id
        builder
            .addCase(getFeatureByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(getFeatureByIdAsync.fulfilled, (state, { payload }) => {
                state.featureById = payload[0]
                state.loading = false;
            })
            .addCase(getFeatureByIdAsync.rejected, (state) => {
                state.loading = false
            })
        // delete feature by id
        builder
            .addCase(deleteFeatureByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteFeatureByIdAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(deleteFeatureByIdAsync.rejected, (state) => {
                state.loading = false
            })
        builder
            .addCase(updateFeatureByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(updateFeatureByIdAsync.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(updateFeatureByIdAsync.rejected, (state) => {
                state.loading = false
            })


    }
});
export const {
    setPageSize,
    setPage,
    setSearch,
    setFeatureById
} = featureSlice.actions
export default featureSlice.reducer;
