import { createSlice } from "@reduxjs/toolkit";
import { cancelSubscriptionAsync ,refundSubscriptionAsync} from "../actions/subscriptonsAction";

const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            params: "",
            rules: [{ index: 0, field: "", operator: "", value: "" }],
        },
        ordering: "name",
        orderBy: [{ field: "name", label: "ASC: Name" }],
    },
    subscriptions: {},
    loading: null,
};

const subscriptionsSlice = createSlice({
    name: "subscriptions",
    initialState,
    reducers: {

        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(cancelSubscriptionAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(cancelSubscriptionAsync.fulfilled, (state, { payload }) => {
                state.subscriptions = payload;
                state.loading = false;
            })
            .addCase(cancelSubscriptionAsync.rejected, (state) => {
                state.loading = false;
            })
            .addCase(refundSubscriptionAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(refundSubscriptionAsync.fulfilled, (state, { payload }) => {
                state.subscriptions = payload;
                state.loading = false;
            })
            .addCase(refundSubscriptionAsync.rejected, (state) => {
                state.loading = false;
            });
    }
});
export const {
    setPageSize,
    setPage,
    setSearch,
} = subscriptionsSlice.actions
export default subscriptionsSlice.reducer;
