import { createSlice } from "@reduxjs/toolkit";
import { getAddonsAsync,createAgencyAddonsAsync,updateAgencyAddonsAsync,deleteAgencyAddonsAsync} from "../actions/agencyAddonsAction";

const initialState = {
    list: {
        results: [],
        pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            params: "",
            rules: [{ index: 0, field: "", operator: "", value: "" }],
        },
        headers:[
            { field: 'agency_name', label: 'Agency Name' },
            { field: 'flag_name', label: 'Flag Name' },
            { field: 'is_enabled', label: 'Status' },
            { field: 'created_on', label: 'Created On' },
            // { field: 'action', label: 'Actions' },
            { field: 'edit', label: 'Edit' },
            { field: 'delete', label: 'Delete' },
    ],
        ordering: "name",
        orderBy: [{ field: "name", label: "ASC: Name" }],
    },
    featureById: {},
    loading: null,
};

const agencyAddons = createSlice({
    name: "featureFlag",
    initialState,
    reducers: {

        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setFeatureById(state, action) {
            state.featureById = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAddonsAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAddonsAsync.fulfilled, (state, { payload }) => {
                state.list.results = payload.results
                state.list.pagination = { ...state.list.pagination, ...payload.pagination }
                state.loading = false;
            })
            .addCase(getAddonsAsync.rejected, (state) => {
                state.loading = false;
            })
        // getting all features
        builder
            .addCase(createAgencyAddonsAsync.pending, (state) => {
            state.loading = true;
             })
            .addCase(createAgencyAddonsAsync.fulfilled, (state, { payload }) => { 
            state.loading = false;
             })
            .addCase(createAgencyAddonsAsync.rejected, (state) => {
             state.loading = false;
             })

        builder
            .addCase(updateAgencyAddonsAsync.pending,(state)=>{
                state.loading = true;
             })
            .addCase(updateAgencyAddonsAsync.fulfilled, (state, { payload }) => { 
                state.loading = false;
            })
            .addCase(updateAgencyAddonsAsync.rejected, (state) => {
                 state.loading = false;
            })
        builder
            .addCase(deleteAgencyAddonsAsync.pending,(state)=>{
                state.loading = true;
             })
            .addCase(deleteAgencyAddonsAsync.fulfilled, (state, { payload }) => { 
                state.loading = false;
            })
            .addCase(deleteAgencyAddonsAsync.rejected, (state) => {
                 state.loading = false;
            })
    }
});
export const {
    setPageSize,
    setPage,
    setSearch,
    setFeatureById
} = agencyAddons.actions
export default agencyAddons.reducer;
