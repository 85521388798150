const LicenceHeaderStyles = (theme) => ({
    width: "100%",
    ".header": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      color:"red"
    },
    ".button_container": {
      display: "flex",
      gap: "10px",
      alignItems: "center"
    },
    ".Licence-header":{
      display:"flex",
      justifyContent:"space-between"
    }
  
  })
  export default LicenceHeaderStyles