const Style = (theme) => ({
    borderRadius: '12px',
    '.icon': {
        height: '32px',
        width: '32px',
        borderRadius: '32px',
        background: '#fff',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.19)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px'
    },
   '.header': {
    padding: '24px 20px 8px',
    display: 'flex',
    alignItems: 'center'
   },
   '.body': {
    padding: '8px 20px 22px'
   },
   '.footer': {
    padding: '4px',
    '& .footerInner': {
        borderRadius: '12px',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '13px 17px',
        height: '40px'
    }
   }
  });
  
  export default Style;
  