import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import Product from "../containers/Product";
import CombinedLayout from "../layouts/AgencyLayout"

const productRoutes = [
    {
        path:"/products",
        element:<ProtectedRoute/>,
        children:[
            {
            element:<CombinedLayout/>,
            children:[
                {
                    path:'',
                    element:<Product/>
                }
            ]
            
            }
        ]
    }
]


export  default productRoutes