import React, { useRef } from 'react';
import { Grid, styled, IconButton, Typography } from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material'; // Assuming you're using MUI icons
import styles from './style'; // Import the styles from the external file
import { UploadIcon2, ProfileEditIcon } from "../../assets/svg/index"
const LogoInput = (props) => {
    const { className, onChange, logo, newLogo } = props;
    const fileInputRef = useRef(null);

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Grid className={className}>
            <Grid className='main'>
                <input
                    className="fileInput"
                    type="file"
                    autoComplete="off"
                    id="invoice_logo"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={onChange}
                />
                <Grid className="logoTextPrev">
                    {logo || newLogo ? (
                        <img alt="invoice logo" src={newLogo || logo} />
                    ) : (
                        <Typography className="noLogo">LOGO</Typography>
                    )}
                </Grid>
                <Grid className="profileEditContainer">
                    <IconButton className="profileEditIcon" onClick={handleIconClick}>

                        {
                            logo || newLogo ? <ProfileEditIcon /> : <UploadIcon2 />
                        }
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default styled(LogoInput)(styles);
