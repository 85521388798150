import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Grid, Button, Typography, styled } from '@mui/material'
import style from './style';
import CustomInput from '../../components/TextField';
import LogoInput from '../../components/LogoInput';
import LicenceHeader from './CreateLicenceHeader/licenceHeader';
import Table from '../../components/Table';
import { useNavigate } from 'react-router-dom';
import SwitchComponent from '../../components/Switch';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import createFeatureSchema from '../Addons/components/validation/createAddonsValidation';
import AddedByComp from '../../components/AddedByComp';
import { useDispatch, useSelector } from 'react-redux';
import { getLicenseAsync, deleteLicenseByIdAsync } from "../../store/actions/licenseAction"
import { ActionCell } from "../../components/MoreAction/MoreAction"
import {
    setPageSize,
    setPage,
    setSearch,
    setLicenseId,
    setlicenseById
} from "../../store/reducers/licenseReducer"
const Licence = (props) => {

    const { className, } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loading, list } = useSelector((state) => state.license);
   
    const { filterHeader } = useSelector((state) => state.ui);
    const { pagination, search } = list

    const {
        control,
        formState: { errors }
    } = useForm({
        resolver: zodResolver(createFeatureSchema)
    });
    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];

    const featureTableHeader = [
        { field: "plan_name", label: "Plan NAME" },
        { field: "status", label: "LICENSE STATUS" },
        { field: "price", label: "LICENSE PRICE" },
        // { field: "plan_code", label: "PLAN CODE" },
        { field: "validity", label: "VALIDITY" },
        { field: "plan_type", label: "PLAN TYPE" },
        { field: "action", label: "ACTION" }
    ];
    const StatusCell = ({ item }) => {
      
        const backgroundColor = item === 1 ? "#D4F6D2" : item === 0 ? "#FFDEDE" : "#FFCCCC";
        const textColor = item === 1 ? "#0E8E2A" : item === 0 ? "#FF9900" : "#FF3300";
        return (
            <span style={{
                display: 'inline-block',
                width: '100px',
                textAlign: 'center',
                color: textColor,
                backgroundColor: backgroundColor,
                borderRadius: '6px',
                padding: '8px 10px'
            }}>
                {item === 1 ? "Active" : "Paused"}
            </span>
        );
    };

    const PriceCell = ({ item }) => {
        return (
            <span style={{
                display: 'inline-block',
                width: '120px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: "#2563EB"
            }}>
                ${item}
            </span>
        );
    };


    const SampleCell = ({ item }) => {

        return <span>{item}</span>;
    };

    const activeCell = ({ data }) => {
        const handleToggle = () => {
            // Implement toggle logic here

        };

        return (
            <SwitchComponent
            // label={activeCell !== "dark" ? "Light Theme" : "Dark Theme"}
            // onClick={() => {
            //   toggleThemeType();
            // }}
            // checked={themeType === "dark"}
            />
        );
    };

    const cellMap = {
        feature_name: SampleCell,
        status: StatusCell,
        price: PriceCell,
        // plan_code: SampleCell,
        validity: SampleCell,
        plan_type: SampleCell,
        action: ActionCell,
    };
    function onEdit(data) {
        navigate(`/products/licence/edit/${data.id}`)
        dispatch(setLicenseId(data.id))
    }
    function onDelete(data) {
        dispatch(deleteLicenseByIdAsync(data?.id))
    }
    useEffect(() => {
        dispatch(getLicenseAsync({ ...pagination, search }))
        dispatch(setlicenseById())
    }, [dispatch, pagination.page_size, pagination.page_number, search])


    const handlePageSize = (e) => {
        dispatch(setPageSize(e.target.value));
    };

    const handlePagination = (e, pageValue) => {
        dispatch(setPage(pageValue));
    };
    const handleSearch = (searchVal) => {
        dispatch(setSearch(searchVal));
    };
    return (

        <Grid className={`${className} createFeatureForm`}>

            <Table
                title={"License"}
                headers={featureTableHeader}
                buttonText={"+ Create License"}
                createUrl={"/products/licence/create"}
                data={list.results}
                cellMap={cellMap}
                onEdit={onEdit}
                onDelete={onDelete}
                search={search}
                pagination={pagination}
                handlePagination={handlePagination}
                handlePageSize={handlePageSize}
                handleSearch={handleSearch}
                isSearch={true}
            />
            <div>{loading ? "Loading..." : ""}</div>
        </Grid>
    )
};
// default props
Licence.defaultProps = {
    classes: {}
};

// prop types
Licence.propTypes = {
    classes: PropTypes.object
};


export default styled(Licence)(style)


