import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, styled } from "@mui/material"
import style from "./style"
import PropTypes from "prop-types";
import { withStyles  } from '@material-ui/core'


const DialogComp= (props)=> {

    const {classes, openDialog, handleDialogClose, userInfo} = props;
    return(
            <Dialog
                open={openDialog}
                onClose={() => handleDialogClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.resetpopup}
            >
                <DialogTitle id="alert-dialog-title">{"Reset Password Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want send password recovery email to {userInfo.name}?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleDialogClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
                    Yes, Proceed
                </Button>
                </DialogActions>
            </Dialog>
    )
}

//defaultProps
DialogComp.defaultProps = {
	classes:{},
}

//propTypes
DialogComp.propTypes = {
  classes:PropTypes.object,
}
// export default styled(DialogComp)(style);

export const CustomDialog = withStyles(style)(DialogComp);