import { z } from 'zod';

const createFeatureSchema = z.object({
    feature_name: z.string().min(1, "Product Name is required"),
    description: z.string()
        .min(5, "Description must be at least 5 characters long")
        .max(80, "Description must be at most 80 characters long"),
    comments: z.string().optional(),
    price: z.string().min(1, "Current Price is required"),
    billing_cycle: z.string().min(1, "Billing cycle is required"),
    // plan_code: z.string().min(1, "Code is required"),
    feature_status: z.string().min(1, "Status is required"),
    feature_flag:z.string().min(1, "Flag is required")
});

export default createFeatureSchema;
