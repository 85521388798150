import React, { useState } from "react";
import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Table as Core,
  TableBody,
  Typography,
  Pagination,
  Select,
  MenuItem,
  IconButton, Menu
} from "@mui/material";
import Style from "./style";
import TableHeader from "./TableHeader";
import { ActionCell } from "../MoreAction/MoreAction";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterHeader from "../Filter/components/FilterHeader"
import { useSelector } from "react-redux";
import {ArrowUp} from "../../assets/svg/index"
const Table = (props) => {
  const {
    className,
    headers,
    data,
    cellMap,
    pagination,
    search,
    handlePagination,
    handlePageSize,
    title,
    handleSearch,
    ordering,
    orderBy,
    onSort,
    filter,
    onFilter,
    buttonText,
    createUrl,
    onEdit,
    onDelete,
    showHeader = false,
    showPagination = false,
    filterOptions,
    setSelectedFilters,
    selectedFilters,
    applyFilter,
    isFilter,
    isSort,
    activeFilter,
    popUp,
    popUpHandler,
    isSearch

  } = props;
  const { filterHeader, filterBar } = useSelector((state) => state.ui);

  const { num_pages, page_number, page_size, count } = pagination || {};
  const startIndex = page_number > 1 ? (page_number - 1) * page_size : 0;
  let endIndex = startIndex + page_size;
  endIndex = endIndex > count ? count : endIndex;

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [active,setActive] = useState("")
  const handleMoreClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAction = (action) => {
    handleClose();
    if (action === "edit") {
      onEdit(selectedRow);
    } else if (action === "delete") {
      onDelete(selectedRow);
    }
  };

  
  const renderCellValue = (field, value, rowData) => {
    const CellComponent = cellMap[field];

    if (CellComponent) {
      return <CellComponent item={value} rowData={rowData} onEdit={onEdit} onDelete={onDelete} />;
    } else {
      return value;
    }
  };

  const tableCell = (header, rowData) => {

    const field = header?.field;


    return (
      <TableCell key={`cell-value-${field}`}>
        <Typography component="div" color={"grey.light"}>
          {renderCellValue(field, rowData[field], rowData)}
        </Typography>
      </TableCell>
    );
  };

  const tableRow = (rowData, i) => {
    return (
      <TableRow key={`row-value-${i}`}>
        {headers?.map((header) => {
          return tableCell(header, rowData);
        })}
        {/* <TableCell>
          <IconButton onClick={(event) => handleMoreClick(event, rowData)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleAction("edit")}>Edit</MenuItem>
            <MenuItem onClick={() => handleAction("delete")}>Delete</MenuItem>
            <MenuItem onClick={() => handleAction("view")}>View</MenuItem>
          </Menu>
        </TableCell> */}
      </TableRow>
    );
  };

  const onSearch = (val) => {
    handleSearch(val);
  };


  return (
    <Grid className={className}>
      {!showHeader && (
        <TableHeader
          title={title}
          onSearch={onSearch}
          onSort={onSort}
          search={search}
          orderBy={orderBy}
          ordering={ordering}
          headers={headers}
          onFilter={onFilter}
          filter={filter}
          buttonText={buttonText}
          createUrl={createUrl}
          isFilter={isFilter}
          isSort={isSort}
          popUp = {popUp}
          popUpHandler = {popUpHandler}
          isSearch={isSearch}
        />
      )}
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="table_container">
        {
          filterHeader && filterOptions && <Grid style={{ width: "100%", padding: "5px" }}>
            <FilterHeader selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} filterOptions={filterOptions} applyFilter={applyFilter} />
          </Grid>
        }
        <TableContainer style={{ padding: "10px" }}>
          <Core stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers && headers?.map((m, i) => {
                  return (
                    <TableCell key={`cell-header-${m.field}`} className={`${active ? 'item-header-active' : 'item-header'}`}>
                      <Grid >
                      <Typography variant="body2" color={"grey.light"}>
                        {m.label}
                      </Typography>
                      </Grid>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data?.map((row, i) => tableRow(row, i))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers?.length}>
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Core>
        </TableContainer>
        {!showPagination && (
          <div className="pagination-wrapper">
            <div className="form-group">
              <label className="form-label">Show Rows:</label>
              <Select
                className="form-field"
                size="small"
                value={page_size}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5 Items</MenuItem>
                <MenuItem value={10}>10 Items</MenuItem>
                <MenuItem value={25}>25 Items</MenuItem>
                <MenuItem value={50}>50 Items</MenuItem>
              </Select>
            </div>
            <div className="form-group">
              {startIndex || 1} to {endIndex} of {pagination?.count}
            </div>
            <Pagination
              count={num_pages}
              page={page_number}
              onChange={handlePagination}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default styled(Table)(Style);
