import React from "react";
import { TextField, InputAdornment } from "@mui/material";

const Text = (props) => {
  const { className, StartIcon, EndIcon, onChange, onSearch, orderBy, ordering, value, ...textProps } = props;

  let idleTimeout = null;

  const handleSearch = (val) => {
    onSearch(val);
  };

  const handleChange = (e) => {
    const val = e.target.value;
    onChange(val);
    if (onSearch) {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => handleSearch(val), 1000);
    }
  };


  return (
    <TextField
      size="small"
      {...textProps}
      value={value}
      InputProps={{
        startAdornment: StartIcon && (
          <InputAdornment position="start">
            <StartIcon />
          </InputAdornment>
        ),
        endAdornment: EndIcon && (
          <InputAdornment position="end">
            <EndIcon />
          </InputAdornment>
        ),
        style: {
          borderRadius: '8px',
          backgroundColor: '#fff',
          color: '#000',
          // padding: `${orderBy ? '20px 24px' : ''} `
        }
      }}
      onChange={handleChange}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'grey',
            borderRadius: '8px'
          }
        }
      }}
    />
  );
};

Text.defaultProps = {
  onChange: () => { },
};

export default Text;
