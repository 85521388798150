import React from "react";
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    Select,
    Typography,
    styled,
} from "@mui/material";
import Style from "./style";
import { CloseIcon } from "../../../assets/svg";


const AddUser = (props) => {
    const { className } = props;

    //


    return (
        <Grid
            container
            className={className}
            spacing={2}
        >
            <Grid item xs={12} md={6}>
                <Grid container justifyContent={"space-between"} alignItems={"center"} className="userItem">
                    <Grid className="dflex aCenter">
                        <Avatar />
                        <Grid className="pl-1">
                            <Typography variant="body1" className="fw-500">User Group</Typography>
                            <Typography variant="body2" color={"text.greyText4"}>User Group</Typography>
                        </Grid>
                    </Grid>
                    <IconButton className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

// default props
AddUser.defaultProps = {
    classes: {}
};

// prop types
AddUser.propTypes = {
    classes: PropTypes.object
};


export default styled(AddUser)(Style);
