import React, { useState } from "react";
import { Grid, IconButton, InputAdornment, MenuItem, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import Text from "../Text";
import Filter from "../Filter";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const TableHeader = (props) => {
  const {
    title,
    search,
    ordering,
    orderBy,
    onSearch,
    onSort,
    headers,
    onFilter,
    filter,
    buttonText,
    createUrl,
    applyFilter,
    isFilter,
    isSort,
    popUp,
    popUpHandler,
    isSearch
  } = props;
const [sortf,setSortf] = useState([{
  name:"agency_name",order:"asc"
}])
  const handleSort = (val) => {
    onSort(val);
  };

  return (
    <Grid className="th-wrapper">
      <h2 className="th-title">{title}</h2>
      <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
        {
          isSearch &&
          <Text
          className="th-search"
          placeholder={"Search"}
          defaultValue={search}
          StartIcon={SearchIcon}
          onSearch={onSearch}
        />
        }
       
        {
          isFilter &&
          <Filter headers={headers} onFilter={onFilter} filter={filter} applyFilter={applyFilter} isFilter={isFilter} />
        }
        {
          isSort &&
          <Grid>
            <Text
              select
              value={ordering}
              orderBy={orderBy}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                  >
                    <IconButton>
                      <SortByAlphaIcon />
                    </IconButton>
                    Sort by
                  </InputAdornment>
                ),
              }}
              onChange={handleSort}
            >
              {orderBy?.map((m) => (
                <MenuItem key={`sort_by_${m.field}`} value={m.field}>
                  {m.label}
                </MenuItem>
              ))}
            </Text>
          </Grid>
        }
        {
          buttonText?.length > 0 ?
            <Grid className="button_container">
              <Link to={createUrl}>
                <Button variant="contained" style={{ textWrap: "wrap", padding: "21px 12px" }}>{buttonText}</Button>
              </Link>
            </Grid> : null
        }
        {
          popUp?.length > 0 ? 
          <Button variant="contained" style={{ textWrap: "wrap", padding: "21px 12px" }} onClick={popUpHandler}>{popUp}</Button>
          : null
        }
      </Grid>
    </Grid>
  );
};

export default TableHeader;
