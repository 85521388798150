import { oldVersionPostRequest, oldVersionGetRequest, oldVersionDeleteRequest, oldVersionUpdateRequest } from "../../utils/apiHelper";

async function createAddons(data){
    return oldVersionPostRequest('addons/create',data)
}

async function getAddons(data) {
    return oldVersionPostRequest('addons/getAll', data)
}

async function deleteAddonsById(param) {
    return oldVersionDeleteRequest(`addons/${param}`)
}

async function getAddonsById(param) {
    if (param) return oldVersionGetRequest(`addons/${param}`)
}
async function updateAddonsById(id, data) {
    return oldVersionUpdateRequest(`addons/${id}`, data);
}
export const addonsService = {
    createAddons,
    getAddons,
    deleteAddonsById,
    getAddonsById,
    updateAddonsById
}