const FormSubHeader = (theme) => ({
    width: "100%",
    background: 'linear-gradient(93.71deg, #19A8FC 0%, #5351FC 100%)',
    padding: "1rem",
    borderRadius: "10px",

    '& .MuiTypography-root': {
        color: "#fff",
        fontWeight: "600"
    }

})

export default FormSubHeader