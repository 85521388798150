const commentsStyles = (theme) => (
    {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        ".heading": {
            fontWeight: 400
        },
        ".image_container": {
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1px solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        ".added_by_comp_main": {
            display: "flex",
            // alignItems: "center",
            gap: "20px",
            justifyContent: "spaceBetween",
            width: "100%"
        },
        ".details_conainer": {
            display: "flex",
            gap: "2px",
            width: "100%"

        },
        ".name_container": {
            display: "flex",
            gap: "5px",
            flexDirection: "column",
            width: "100%"
        }
    }
)
export default commentsStyles