import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Grid, styled } from "@mui/material";
import Style from "./style";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import theme from "../../theme";

const MenuItem = ({ item, permissions, compIndex, handleToggle, openToggle }) => {
  const location = useLocation();
  
  // Check if the current route is a create or edit route
  const isCreateOrEditRoute = location.pathname.includes("/create") || location.pathname.includes("/edit");

  // Check if the current item should be active (excluding create/edit routes)
  const isActiveLink = !isCreateOrEditRoute && location.pathname === item.link;

  const hasChildren = item.children && item.children.length > 0;

  // Check if any child route matches
  const isActiveChild = hasChildren && item.children.some(child => location.pathname.startsWith(child.link));

  // Allow child elements to be active on /create or /edit routes
  const isActiveParent = isActiveChild && !isActiveLink;

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          component={NavLink}
          to={item.link}
          onClick={hasChildren ? () => handleToggle(compIndex) : undefined}
          selected={isActiveLink || (isActiveParent && !isCreateOrEditRoute)}
          className={isActiveParent || isActiveLink ? "active" : "nonActive"}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{item.name}</Typography>} />
          {hasChildren && (openToggle ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </ListItemButton>
      </ListItem>
      {hasChildren && (
        <Collapse in={openToggle} timeout="auto" unmountOnExit>
          {item.children.map((child, index) => (
            <ListItem key={`submenu_${index}_${child.name}`} disablePadding sx={{ pl: 4 }}>
              <ListItemButton
                component={NavLink}
                to={child.link}
                selected={location.pathname.startsWith(child.link)}
                className={location.pathname.startsWith(child.link) ? "activeChild" : "nonActiveChild"}
              >
                <ListItemIcon>{child.icon}</ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body1" style={{ color: location.pathname.startsWith(child.link) ? theme.palette.primary.main : theme.palette.grey.main }}>{child.name}</Typography>}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </Collapse>
      )}
    </>
  );
};




const MenuListComponent = ({ items, permissions, className }) => {
  const [isOpen, setIsOpen] = useState(null);

  const handleToggle = (index) => {
    setIsOpen((prev) => prev = false)
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <Grid className={className}>
      {items.map((item, index) => (
        <Grid key={index} className="menu_item">
          <MenuItem
            compIndex={index}
            item={item}
            permissions={permissions}
            openToggle={isOpen}
            handleToggle={handleToggle}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const MenuList = styled(MenuListComponent)(({ theme }) => Style(theme));

export default MenuList;
