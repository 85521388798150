import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import Style from "./style";
import { CloseIcon, CreatePaymentIcon } from "../../../assets/svg";

const CreatePaymentLink = (props) => {
  const { className } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contaned"
        color="primary"
        className=""
        startIcon={<CreatePaymentIcon />}
        onClick={handleClickOpen}
      >
        Create Payment Link
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className={className}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container className="popup">
          <Grid item xs={12} className="header">
              <Typography variant="body1" className="fw-700 uppercase">Create Payment link</Typography>
              <IconButton className="closeBtn" onClick={handleClose}><CloseIcon /></IconButton>
          </Grid>
          <Grid item xs={12} className="body"></Grid>
          <Grid item xs={12} className="footer">
            <Button variant="outlined" color="primary" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary">Send</Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

// default props
CreatePaymentLink.defaultProps = {};

// prop types
CreatePaymentLink.propTypes = {};

export default styled(CreatePaymentLink)(Style);
