import React, { useEffect, useState } from 'react';
import createFormStyles from './createAddonsFormStyles';
import { Grid, Typography, styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import CustomButton from "../../../../components/Button/ButtonComp";
import FormHeader from '../CreateAddonHeader/formHeader';
import FormSubHeaderComp from "../CreateFormSubHeader/formSubHeader";
import CustomInput from "../../../../components/TextField/index";
import LogoInput from "../../../../components/LogoInput";
import RichTextAreaComponent from "../../../../components/TextArea/index";
import AddedByComp from "../../../../components/AddedByComp";
import CustomSelect from "../../../../components/InputSelect";
import { useDispatch, useSelector } from 'react-redux';
import { createfeatureAsync, getFeatureByIdAsync, updateFeatureByIdAsync } from "../../../../store/actions/featureAction";
import { createAddonsAsync, updateAddonsByIdAsync } from "../../../../store/actions/addonsAction";
import { getLicenseAsync, deleteLicenseByIdAsync } from "../../../../store/actions/licenseAction"
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import cretaeAddonsSchema from '../validation/createAddonsValidation';
import Comments from '../Comments/comments';
import { useParams, useLocation, } from 'react-router-dom';
import {getAddonsByIdAsync} from "../../../../store/actions/addonsAction"
const CreateAddonsForm = (props) => {
    const dispatch = useDispatch();
    const { className } = props;
    const [logo, setLogo] = useState(null)
    const [imageType, setImageType] = useState(null)
    const [openTier, setOpenTier] = useState(false)
    const [error, setError] = useState("")
    const { id } = useParams()
    
    const location = useLocation()

    const { loading, list, featureById } = useSelector((state) => state.addons);
    const licenseReducer = useSelector((state) => state.license)
    
    const { price,
        price_id,
        currency,
        name,
        list_in_payment,
        title,
        description,
        plan_label,
        product,
        addon_type, feature_name, feature_description, featureType, featureStatus, unit_amount, featureCode, feature_image_url, auditInfo, metadata } = featureById

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: zodResolver(cretaeAddonsSchema),
        defaultValues: {
            name: name || "",
            description: description || "",
            featureType: featureType || "",
            featureStatus: metadata?.featureStatus || "",
            unit_amount: price || "",

        }

    });
    useEffect(() => {
        if (featureById) {
            const {
                price_id,
                currency,
                name,
                list_in_payment,
                title,
                price,
                description,
                plan_label,
                addon_type, feature_name, feature_description, featureType, featureStatus, featureCode, feature_image_url,
                metadata,
                billing_cycle,
                tiers,
                product
            } = featureById;
            setValue("name", name);
            setValue("description", description);
            setValue("featureType", featureType);
            setValue("featureStatus", metadata?.featureStatus);
            setValue("price", price);
            setValue("featureCode", featureCode);
            setValue("title", title);
            setValue("plan_label", plan_label);
            setValue("addon_types", addon_type)
            setValue("plan_code", metadata?.featureCode)
            setLogo(feature_image_url);
            setValue("billing_cycle", billing_cycle)
            setValue("start_unit_amount", tiers && tiers[0]?.unit_amount / 100)
            setValue("upto_count_start", tiers && tiers[0]?.up_to)
            setValue("end_unit_amount", tiers && tiers[1]?.unit_amount / 1000)
            setValue("upto_count_end", tiers && tiers[1]?.up_to === null ? 'inf' : tiers && tiers[1]?.upto_count_end)
            setOpenTier(tiers?.length > 0 ? true : false)
        }
    }, [featureById, setValue]);
    useEffect(() => {
        dispatch(getLicenseAsync({
            count: 30,
            next: null,
            previous: null,
            page_size: 30,
            num_pages: 0,
            page_number: 1,
        }))
    }, [])

    let obj = {}
    const planCodeOptions = licenseReducer?.list?.results?.map((data) => {
        return { value: data.plan_code, label: data.plan_code }
    })
 
    const edit = location.pathname.includes('edit')
  

    const onSubmit = (data) => {
     
        if (id) {
            dispatch(updateAddonsByIdAsync({
                data: {
                    ...data, stripe_id: product?.id, metadata: {
                        featureType: data.featureType,
                        featureStatus: data.featureStatus,
                        featureCode: data.featureCode,
                        featureVisibility: data.featureVisibility,
                        tags: "sample"
                    }, currency: "usd",
                    updatedBy: "Flozy user",
                    feature_image_url: feature_image_url,
                    ...imageType
                },
                id: featureById.id
            }))
           

        } else {
            dispatch(createAddonsAsync(({
                ...data, ...imageType, imageName: "image", tags: "sample", metadata: {
                    featureType: data.addonType,
                    featureStatus: data.featureStatus,
                    featureCode: data.featureCode,
                    featureVisibility: "Feature ",
                }, price: parseInt(data.price),
                tiers: openTier ? [
                    {
                        "unit_amount": data?.start_unit_amount,
                        "up_to": data.upto_count_start
                    },
                    {
                        "unit_amount": data?.end_unit_amount,
                        "up_to": data.upto_count_end
                    }
                ] : null,
                commentedBy: "Flozy user",
                tiers_mode: openTier ? "graduated" : "volume",
                billing_cycle: parseInt(data.billing_cycle),
                billing_scheme: openTier ? "tiered" : "per_unit",
                link: {
                    plan_code: data.plan_code,
                    addon_type: data.addon_types
                },
                currency: "USD",
                description: data.description,
                status: data.featureStatus
            })));

        }
    };




    useEffect(() => {
        if (id) {
            dispatch(getAddonsByIdAsync(id))
        }
    }, [id])


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {

            if (file.size > 1024 * 1024) { // Check if file size exceeds 1MB
                setError('File size should be less than 1MB');
                return;
            }
            setError(''); // Clear previous error messages
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result;
                const imageMimeType = file.type;
                setLogo(e.target.result);
                setImageType({
                    base64Image: base64String?.split(',')[1],
                    imageMimeType: imageMimeType,
                    imageName: "image"
                });
            };
            reader.readAsDataURL(file);
        }
    };
  
    const statusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Paused' },
        { value: '2', label: 'Disabled' },

    ];
const addonType = [
    { value: 'client_user', label: 'Client User' },
    { value: 'team_user', label: 'Team User' },
]

    const billingCycle = [
        { value: '1', label: 'Day' },
        { value: '2', label: 'Week' },
        { value: '3', label: 'Month' },
    ]
   

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className={`${className} createFeatureForm`}>
                <Grid>
                    <FormHeader title={'Addon'} error={error} />
                    <FormSubHeaderComp />
                </Grid>
                <Grid className='form_container'>
                    <Grid className='form_container_right'>
                        <Grid className='form_container_right_sub'>
                            <Grid>
                                <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Addon Name</Typography>
                          
                                <Controller
                                    name="name"
                                    control={control}

                                    render={({ field }) => <CustomInput  {...field} defaultValue={name} />}
                                />
                                {errors.featureName && <span className='error_text'>{errors.featureName.message}</span>}
                            </Grid>
                            <Grid>
                                <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Addon Image</Typography>
                                <Controller
                                    name="productImage"
                                    control={control}

                                    render={({ field }) => <LogoInput {...field} onChange={handleImageChange} logo={logo} />}
                                />
                                {error && <span className='error_text'>{error}</span>}
                            </Grid>
                            <Grid>
                                <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Description</Typography>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => <RichTextAreaComponent {...field} defaultValue={feature_description} />}
                                />
                                {errors.description && <span className='error_text'>{errors.description.message}</span>}
                            </Grid>
                            <Grid style={{ display: "flex" }}>
                                <AddedByComp heading="Added By" addedBy={
                                    auditInfo?.addedBy
                                } addedByRole={
                                    auditInfo?.addedByRole
                                } />
                                <AddedByComp heading="Edited By" editedBy={
                                    auditInfo?.editedBy
                                } editedByRole={
                                    auditInfo?.editedByRole
                                } />
                            </Grid>
                        </Grid>
                        <Grid className='tier_container'>
                            <Grid style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}>
                                <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Tiers</Typography>
                                <Switch onChange={() => setOpenTier(!openTier)} checked={openTier} />
                            </Grid>
                            <Typography>Billling Scheme</Typography>
                            <Controller
                                name="billing_cycle"
                                control={control}
                                render={({ field }) => <CustomSelect {...field} options={billingCycle} />}
                            />
                            {errors.billing_cycle && <span className='error_text'>{errors.billing_cycle.message}</span>}

                            {
                                openTier &&
                                <Grid>

                                    <Grid className='from_tier'>
                                        <Typography>From Tier</Typography>
                                        <Typography variant='h6' >Start Unit Amount</Typography>
                                        <Controller
                                            name="start_unit_amount"
                                            control={control}

                                            render={({ field }) => <CustomInput  {...field} defaultValue={feature_name} />}
                                        />

                                        <Typography variant='h6' >Start Unit Count</Typography>
                                        <Controller
                                            name="upto_count_start"
                                            control={control}

                                            render={({ field }) => <CustomInput  {...field} defaultValue={feature_name} />}
                                        />

                                    </Grid>
                                    <Grid className='to_tier'>
                                        <Typography>To Tier</Typography>
                                        <Typography variant='h6' >End Unit Amount</Typography>
                                        <Controller
                                            name="end_unit_amount"
                                            control={control}

                                            render={({ field }) => <CustomInput  {...field} defaultValue={feature_name} />}
                                        />

                                        <Typography variant='h6' >End Unit Count</Typography>
                                        <Controller
                                            name="upto_count_end"
                                            control={control}

                                            render={({ field }) => <CustomInput  {...field} defaultValue={feature_name} />}
                                        />

                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid className='form_container_right_sub'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Add Comments</Typography>
                            <Controller
                                name="comments"
                                control={control}
                                render={({ field }) => <CustomInput {...field} defaultValue={feature_description} />}
                            />
                            {errors.comments && <span className='error_text'>{errors.comments.message}</span>}
                            <Comments commentsData={featureById} />
                        </Grid>
                    </Grid>
                    <Grid className='main_form_container_left'>
                        <Grid className='form_container_left'>
                            <Typography variant='h4' style={{ fontWeight: "600", fontSize: "16px" }}>Primary Details</Typography>
                            <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Typography variant='h5'>Addon Type</Typography>
                                <Controller
                                    name="addon_types"
                                    control={control}
                                    render={({ field }) => <CustomSelect {...field} defaultValue={addon_type}  options={addonType}/>}
                                />
                                {errors.featureType && <span className='error_text'>{errors.featureType.message}</span>}
                                <Typography variant='h5'>Addon Status</Typography>
                                <Controller
                                    name="featureStatus"
                                    control={control}
                                    render={({ field }) => <CustomSelect {...field} options={statusOptions} defaultValue={metadata?.featureStatus} />}
                                />
                                {errors.featureStatus && <span className='error_text'>{errors.featureStatus.message}</span>}

                                <Typography variant='h5'>Plan Code</Typography>
                                <Controller
                                    name="plan_code"
                                    control={control}
                                    render={({ field }) => <CustomSelect {...field} options={planCodeOptions} defaultValue={metadata?.featureCode} />}
                                />
                                {errors.plan_code && <span className='error_text'>{errors.plan_code.message}</span>}
                            </Grid>
                        </Grid>
                        <Grid className='form_container_left'>
                            <Typography variant='h4' style={{ fontWeight: "600", fontSize: "16px" }}>Addon Pricing Details</Typography>
                            <Grid style={{ display: "flex", flexDirection: "column", gap: "7px" }}>
                                <Typography variant='h5'>Current Price</Typography>
                                <Controller
                                    name="price"
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} defaultValue={price} />}
                                />
                                {errors.price && <span className='error_text'>{errors.price.message}</span>}
                            </Grid>

                        </Grid>
                        <Grid className='form_container_left'>
                            <Typography variant='h4' style={{ fontWeight: "600", fontSize: "16px" }}>Addon  Details</Typography>
                            <Grid style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <Typography variant='h5'>Title</Typography>
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} defaultValue={unit_amount} />}
                                />
                                {errors.unit_amount && <span className='error_text'>{errors.unit_amount.message}</span>}
                                <Typography variant='h5'>Feature Label</Typography>
                                <Controller
                                    name="plan_label"
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} defaultValue={featureCode} />}
                                />
                                {errors.featureCode && <span className='error_text'>{errors.featureCode.message}</span>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default styled(CreateAddonsForm)(createFormStyles);
