import { Grid, Typography, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Style from "./style";
import {} from "../../../assets/svg";
import SwitchComponent from "../../../components/Switch";

const PaymentCard = (props) => {
  const { className, name, icon, switchText, description } = props;
  return (
    <Grid container className={`${className} paymentCard`}>
      <Grid item xs={12} className="header">
        <Grid className="icon">
            {icon}
        </Grid>
        <Grid>
          <Typography variant="h5" className="fw-600" color={"text.whiteText"}>
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Grid className="body" item xs={12}>
        <Typography variant="body2" className="fs-10" color={"text.whiteText"}>
          {description}
        </Typography>
      </Grid>
      <Grid className="footer" item xs={12}>
        <Grid className="footerInner">
            <Typography variant="body1" color={"text.greyText4"}>{switchText}</Typography>
            <SwitchComponent />
        </Grid>
      </Grid>
    </Grid>
  );
};

// default props
PaymentCard.defaultProps = {
    name: '',
    description: '',
    switchText: ''
  };
  
  // prop types
  PaymentCard.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    switchText: PropTypes.string,
    
  };

export default styled(PaymentCard)(Style)
// const PaymentCard = styled(PaymentCardComponent)(({ theme }) => Style(theme));

// export default PaymentCard;
