
import React, { useEffect, useState } from 'react';
import { Grid, Typography, styled, FormControlLabel, Radio, RadioGroup, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod'
import createLicenceFormStyles from './createLicenceFromStyles'
import CustomInput from '../../../components/TextField';
import Table from '../../../components/Table';

import LogoInput from '../../../components/LogoInput';
import createLicenceSchema from '../validation/createLicenceValidation'
import SwitchComponent from '../../../components/Switch';
import FormHeader from '../../Addons/components/CreateAddonHeader/formHeader';
import CustomSelect from '../../../components/InputSelect';
import AddedByComp from '../../../components/AddedByComp';
import { createlicenseAsync, deleteLicenseByIdAsync, getLicenseByIdAsync, updateLicenseAsync } from '../../../store/actions/licenseAction';
import { getAddonsAsync } from "../../../store/actions/addonsAction"
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DownArrow } from '../../../assets/svg';
import {
    setPageSize,
    setPage,
    setSearch
} from "../../../store/reducers/featureReducer";
import { updateLicenceById } from '../../../store/services/licenseService';
import {getfeatureAsync} from "../../../store/actions/featureAction"
const CreateLicenceForm = (props, classes) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { className, } = props;
    const { id } = useParams()
    const { licenseById, licenseId } = useSelector((state) => state.license);
   
    const {
        plan_code,
        plan_type,
        plan_category,
        plan_name,
        plan_description,
        price,
        pre_offer_price,
        extra_price,
        user_count,
        client_user_count,
        currency,
        country,
        validity,
        trial_period,
        stripe_plan_id,
        plan_period,
        payment_type,
        url_code,
        page_title,
        show_plan,
        status,
        deleted,
        created_by,
        created_on,
        modified_by,
        modified_on,
        addon_enabled,
        is_active,
        addon_config,
        image_url,
        features,
    } = licenseById;

    const { list } = useSelector((state) => state.addons)
    const { loading , list:featureList, } = useSelector((state) => state.feature);
    const [imageType, setImageType] = useState(null)
    const [logo, setLogo] = useState(null)

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [showPlan, setShowPlan] = useState('')
    const [addons, setAddons] = useState([])
    const [feature,setFeature] = useState([])
    const [modifyAddon, setModifyAddon] = useState({})
    const [modifyFeature,setModifyFeature] = useState({});

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            plan_name: plan_name || '',
            plan_description: plan_description || "",
            plan_type: plan_type || "",
            plan_category: plan_category || "",
            validity: validity || "",
            plan_code: plan_code || "",
            trial_period: trial_period || "",
            url_code: url_code || "",
            price: price ? price + '' : "",
            pre_offer_price: pre_offer_price ? pre_offer_price + '' : "",
            extra_price: extra_price ? extra_price + '' : "",
            payment_type: payment_type || "",
            plan_period: plan_period || "",
            user_count: user_count || "",
            client_user_count: client_user_count || "",

        },
        resolver: zodResolver(createLicenceSchema),
    });
    useEffect(() => {
        if (licenseById) {
            const {
                plan_name,
                plan_description,
                plan_type,
                plan_category,
                validity,
                plan_code,
                trial_period,
                url_code,
                price,
                pre_offer_price,
                extra_price,
                payment_type,
                plan_period,
                user_count,
                client_user_count
            } = licenseById;

            setValue("plan_name", plan_name || "");
            setValue("plan_description", plan_description || "");
            setValue("plan_type", plan_type || "");
            setValue("plan_category", plan_category || "");
            setValue("validity", validity || "");
            setValue("plan_code", plan_code || "");
            setValue("trial_period", trial_period || "");
            setValue("url_code", url_code || "");
            setValue("price", price || "");
            setValue("pre_offer_price", pre_offer_price || "");
            setValue("extra_price", extra_price || "");
            setValue("payment_type", payment_type || "");
            setValue("plan_period", plan_period || "");
            setValue("user_count", user_count || "");
            setValue("status", status || "");
            setLogo(image_url)
            setValue("client_user_count", client_user_count || "");
           
            // if (addon_config) {
            //     const parsed = JSON.parse(addon_config)
            //     let arr = []

            //     Object.keys(parsed).forEach(key => {
            //         const newObj = { [key]: parsed[key] };
            //         const addonObject = { name: key, id: parsed[key] }
            //         arr.push(addonObject);
            //     });
            //     setAddons(arr)
            // }

        }
    }, [licenseById, setValue]);
    useEffect(() => {
        dispatch(getAddonsAsync({ ...list.pagination }))
    }, [dispatch, list.pagination.page_size, list.pagination.page_number, list.search])


    useEffect(() => {
        dispatch(getLicenseByIdAsync(id))
        // if (addon_config) {
        //     let arr = []
        //     const newParsed = JSON.parse(addon_config)
        //     Object.keys(newParsed).forEach(key => {
        //         const newObj = { [key]: newParsed[key] };
        //         arr.push(newObj);
        //     })

        // }

    }, [id])

    const goPreviuosRoute = ()=>{
        navigate(-1)
    }
    const onSubmit = (data) => {
        console.log(data,"See hereee")
        const licenseData = {
            status: selectedStatus === 'Active' ? 1 : 0,
            currency: "USD",
            country: "US",
            added_by: "Flozy ",
            created_by: 1,
            addon_config: modifyAddon ? modifyAddon : "",
            imageName: "Image Name",
            user_count: parseInt(data.user_count),
            client_user_count: parseInt(data.client_user_count),
            validity: parseInt(data.validity),
            price: parseInt(data.price),
            pre_offer_price: parseInt(data.pre_offer_price),
            extra_price: parseInt(data.extra_price),
            trial_period: parseInt(data.trial_period),
            showPlan: showPlan ? 1 : 0,
            image_url: image_url ? image_url : null,


        };
        const imageUrl = imageType;

        if (id) {
            dispatch(updateLicenseAsync({ id: licenseById.id, data: { ...licenseData, ...data, ...imageUrl, stripe_plan_id: stripe_plan_id ,plan_features:modifyFeature} }))
        } else {

            dispatch(createlicenseAsync({ ...licenseData, ...data, ...imageUrl }));
        }
    };

    const planType = [
        { value: 'solopreneurPlan', label: 'Solopreneur Plan' },
        { value: 'educatePlan', label: 'Educate Plan' },
        { value: 'professionalPlan', label: 'Professional Plan' },
        { value: 'premium', label: 'Premium' },
        { value: 'standard', label: 'Standard' },
    ];


    const ActiveOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'unactive' },

    ]
    const featureTableHeader = [
        { field: "name", label: "Addons NAME" },
        { field: "price", label: "AMOUNT" },
        { field: "action", label: "ACTION" }
    ];
    const addonsTableHeader = [
        { field: "feature_name", label: "Feature NAME" },
        { field: "price", label: "AMOUNT" },
        { field: "action", label: "ACTION" }
      ];
    const planPeriod = [
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' },

    ]
    const paymentType = [
        { value: '1', label: 'Online' },
        { value: '0', label: 'Offline' },

    ]
    const SampleCell = ({ item }) => {
        return <span>{item}</span>;
    };
    const StatusCell = ({ item }) => {
        const color = item === "Completed" ? "green" : item === "Pending" ? "orange" : "red";
        return <span style={{ color }}>{item}</span>;
    };

    const addAddons = (item) => {
        const { name, id, addon_type } = item;
  
        setAddons((prevAddons) => {
          const ifExist = prevAddons.some((data) => Number(data.id) === Number(id));  
          const findSome = prevAddons.find((data) => data.name === addon_type); 
          let newAddons;
          if (ifExist) {
            newAddons = prevAddons.filter((filter) => Number(filter.id) !== Number(id));
            return newAddons;
          } else if (findSome) {
            newAddons = prevAddons.filter((filter) => filter.name !== addon_type);
            newAddons = [...newAddons, { name:addon_type, id }];
            return newAddons;
          } else {
            newAddons = [...prevAddons, { name:addon_type, id }];
            return newAddons;
          }
        });
      };
    const addFeatures = (item)=>{
        const {feature_name , id} = item;
        setFeature((prevFeature)=>{
        const ifExist = prevFeature.some((data)=>Number(data.id) === Number(id))
        let newFeature;
        if(ifExist){
            newFeature = prevFeature.filter((filter)=> filter.id !== id)
            return newFeature
        }else{
            newFeature = [...prevFeature,{name:feature_name,id}]
            return newFeature
        }
        })
    }

  
    const activeCell = (props) => {
        const { rowData } = props;

        const handleToggle = () => {
            addAddons(rowData);
        };

        const isChecked = addons.some(addon => Number(addon.id) === Number(rowData.id));
  
        return (
            <SwitchComponent onChange={handleToggle} checked={isChecked} />
        );
    };


    const featureActiveCell = (props) => {
        const { rowData } = props;

        const handleToggle = () => {
            addFeatures(rowData);
        };

        const isChecked = feature.some(feature => Number(feature.id) === Number(rowData.id));
  
        return (
            <SwitchComponent onChange={handleToggle} checked={isChecked} />
        );
    };
    const PriceCell = ({ item }) => {
        return (
            <span style={{
                display: 'inline-block',
                width: '120px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: "#2563EB"
            }}>
                ₹{item}
            </span>
        );
    };

    const cellMap = {

        feature_name: SampleCell,
        license_status: StatusCell,
        license_price: PriceCell,
        plan_code: SampleCell,
        validity: SampleCell,
        plan_type: SampleCell,
        action: activeCell,

    };
    const featureCellMap = {
        feature_name: SampleCell,
        license_status: StatusCell,
        license_price: PriceCell,
        plan_code: SampleCell,
        validity: SampleCell,
        plan_type: SampleCell,
        action: featureActiveCell,

    };

    function onEdit(data) {

        navigate(`/licence/${data?.stripe_plan_id}`)
    }
    function onDelete(data) {
        dispatch(deleteLicenseByIdAsync(data?.id))
    }
    const handleImageChange = (e) => {

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result;
                const imageMimeType = file.type;
                setLogo(e.target.result);
                setImageType({
                    base64Image: base64String?.split(',')[1],
                    imageMimeType: imageMimeType
                })
            };
            reader.readAsDataURL(file);
        }

    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAction = (action) => {


    };
    const handleStatusChange = (e) => {

    console.log(e.target.value,"see chnage value")
    };
    const [specialAdd, setSpecialAdd] = useState(''); // state to manage selected radio button
    const handleSpecialAddChange = (event) => {
        setSpecialAdd(event.target.value);
    };

    const handlePagination = (e, pageValue) => {
        dispatch(setPage(pageValue));
    };
    const handlePageSize = (e) => {
        dispatch(setPageSize(e.target.value));
    };
 
    useEffect(() => {
        if (addon_config) {
            const parsed = JSON.parse(addon_config);
            const arr = Object.keys(parsed).map(key => ({
                name: key,
                id: Number(parsed[key]),
                // Ensure ID is a number
            }));
            setAddons(arr);
        }
    }, [addon_config]);
    useEffect(() => {
        if (features) {
            const parsed = JSON.parse(features);
            const arr = Object.keys(parsed).map(key => ({
                name: key,
                id: Number(parsed[key]),
                // Ensure ID is a number
            }));
            setFeature(arr);
        }
    }, [features]);
    
    useEffect(() => {
        const obj = addons?.reduce((acc, { name, id }) => {
            acc[name] = id;
            return acc;
        }, {});
     
        setModifyAddon(obj);
    }, [addons]);
  

    useEffect(()=>{
        if(feature.length > 0){
            const obj = feature?.reduce((acc,{name, id})=>{
                acc[name] = id
                return acc
            },{})
        
            setModifyFeature(obj)
        }
    
    },[feature])

    useEffect(() => {
        dispatch(getfeatureAsync({ ...featureList,search:featureList.search }));
      }, [dispatch, featureList.pagination.page_size, featureList.pagination.page_number,featureList.search]);
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className={`${className} createFeatureForm`}>
                <Grid>
                    <FormHeader title={'Licence'} />
                </Grid>
                <Grid className='licence_container'>
                    <Grid className='licence_name'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Licence Name</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>Name of the feature and status be mentioned here</Typography>
                        </Grid>
                        <Grid className='license_image_con'>
                            <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px", color: "#73757A" }}>Name</Typography>
                                <Controller
                                    name="plan_name"
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} defaultValue={plan_name} />}
                                />
                                {errors.plan_name && <span className='error_text'>{errors.plan_name.message}</span>}
                            </Grid>
                            <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px", color: "#73757A" }}>Picture</Typography>
                                <Controller
                                    name="productImage"
                                    control={control}

                                    render={({ field }) => <LogoInput {...field} onChange={handleImageChange} logo={logo} />}
                                />
                            </Grid>
                        </Grid>
                        <Grid style={{display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                           <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px", color: "#64748B" }}>Status</Typography>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => <CustomSelect {...field} options={ActiveOptions} defaultValue={status}/>}
                                    />
                                    {errors.plan_type && <span className='error_text'>{errors.plan_type.message}</span>}
                          </Grid> 
                    </Grid>
                    <Grid className='licence-end'></Grid>

                    {/* description*/}

                    <Grid className='license_description'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Details</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The description and tags of the above feature for understanding be mentioned here. </Typography>

                        </Grid>
                        <Grid className='licence-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px", color: "#73757A" }}>Description </Typography>
                            <Controller
                                name="plan_description"
                                control={control}
                                render={({ field }) => <CustomInput {...field} defaultValue={plan_description} />}
                            />
                            {errors.description && <span className='error_text'>{errors.description.message}</span>}
                        </Grid>
                        <Grid style={{ opacity: 0 }}>
                            kjjer
                        </Grid>
                    </Grid>

                    {/* addons list */}

                    <Grid className='licence-end'></Grid>
                    <Grid className='licence_addons'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Add Addons</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The description and tags of the above addons for understanding be mentioned here. </Typography>

                        </Grid>
                        <Grid style={{ height: "100%" }}>
                            <Table
                                // title={"Licence"}
                                headers={featureTableHeader}
                                data={id ? list?.results : []}
                                cellMap={cellMap}
                                showHeader={true}
                                search={list.search}
                                pagination={list.pagination}
                                handlePagination={handlePagination}
                                handlePageSize={handlePageSize}
                            />
                        </Grid>
                    </Grid>
                    <Grid className='licence-end'></Grid>
                    <Grid className='licence_addons'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Add feature</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The description and tags of the above feature for understanding be mentioned here. </Typography>

                        </Grid>
                        <Grid style={{ height: "100%" }}>
                            <Table
                                // title={"Licence"}
                                headers={addonsTableHeader}
                                data={id? featureList?.results: []}
                                cellMap={featureCellMap}
                                showHeader={true}
                                search={featureList.search}
                                pagination={featureList.pagination}
                            />
                        </Grid>
                    </Grid>
                    <Grid className='licence-end'></Grid>
                    <Grid className='licence_add_details'>
                        <Grid>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>License Additional Details</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The additional details above the license details </Typography>
                        </Grid>
                        <Grid style={{width:"100%"}}>
                            <Grid className='plan-details'>
                                <Grid className='licence-detail'  style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Plan code</Typography>
                                    <Controller
                                        name="plan_code"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={plan_code} />}
                                    />
                                    {errors.plan_code && <span className='error_text'>{errors.plan_code.message}</span>}

                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Plan type</Typography>

                                    <Controller
                                        name="plan_type"
                                        control={control}
                                        render={({ field }) => <CustomSelect {...field} options={planType} defaultValue={plan_type} />}
                                    />
                                    {errors.plan_type && <span className='error_text'>{errors.plan_type.message}</span>}
                                </Grid>

                            </Grid>

                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Plan category </Typography>
                                    <Controller
                                        name="plan_category"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={plan_category} />}
                                    />
                                    {errors.plan_category && <span className='error_text'>{errors.plan_category.message}</span>}
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Page title</Typography>
                                    <Controller
                                        name="page_title"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={page_title} />}
                                    />
                                    {errors.page_title && <span className='error_text'>{errors.page_title.message}</span>}
                                </Grid>

                            </Grid>

                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Trial period</Typography>
                                    <Controller
                                        name="trial_period"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={trial_period} />}
                                    />
                                    {errors.trial_period && <span className='error_text'>{errors.trial_period.message}</span>}
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Validity</Typography>
                                    <Controller
                                        name="validity"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={validity} />}
                                    />
                                    {errors.validity && <span className='error_text'>{errors.validity.message}</span>}
                                </Grid>

                            </Grid>
                            <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Show plan
                                    <SwitchComponent onChange={() => setShowPlan(!showPlan)} /></Typography>
                                <Controller
                                    name="url_code"
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} placeholder="Enter URL" defaultValue={url_code} />}
                                />
                                {errors.url_code && <span className='error_text'>{errors.url_code.message}</span>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='pricing_details'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>Pricing Details</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The amount of the particular feature and the stripe code/Id be mentioned here. </Typography>

                        </Grid>
                        <Grid style={{width:"100%"}}>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Price</Typography>
                                    <Controller
                                        name="price"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={price} disabled={false} />}
                                    />
                                    {errors.price && <span className='error_text'>{errors.price.message}</span>}
                                    {/* <Typography className='activeCell-Price'>$ Total</Typography> */}

                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Pre Offer Price</Typography>
                                    <Controller
                                        name="pre_offer_price"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={pre_offer_price} disabled={false} />}
                                    />
                                    {errors.pre_offer_price && <span className='error_text'>{errors.pre_offer_price.message}</span>}

                                </Grid>
                            </Grid>
                            <Grid style={{ display: "flex" }}>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Extra Price</Typography>
                                    <Controller
                                        name="extra_price"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={extra_price} disabled={false} />}
                                    />
                                    {errors.extra_price && <span className='error_text'>{errors.extra_price.message}</span>}
                                </Grid>
                            </Grid>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Payment Type</Typography>
                                    <Controller
                                        name="payment_type"
                                        control={control}
                                        render={({ field }) => <CustomSelect {...field} options={paymentType} defaultValue={payment_type} />}
                                    />
                                    {errors.payment_type && <span className='error_text'>{errors.payment_type.message}</span>}
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Plan Period</Typography>
                                    <Controller
                                        name="plan_period"
                                        control={control}
                                        render={({ field }) => <CustomSelect {...field} options={planPeriod} defaultValue={plan_period} />}
                                    />
                                    {errors.plan_period && <span className='error_text'>{errors.plan_period.message}</span>}
                                </Grid>
                            </Grid>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Users Count</Typography>
                                    <Controller
                                        name="user_count"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={user_count} />}
                                    />
                                    {errors.user_count && <span className='error_text'>{errors.user_count.message}</span>}
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Client User Count</Typography>
                                    <Controller
                                        name="client_user_count"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={client_user_count} />}
                                    />
                                    {errors.client_user_count && <span className='error_text'>{errors.client_user_count.message}</span>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='licence-end'></Grid>

                    {/* <Grid style={{ display: "flex", paddingTop: "20px" }}>
                        <AddedByComp name="Flozy user" date="22-June-2024" role="Sales Executive" />
                        <AddedByComp name="Flozy user" date="22-June-2024" role="Sales Executive" />
                    </Grid> */}


                </Grid>

            </Grid>
        </form>
    );
};

export default styled(CreateLicenceForm)(createLicenceFormStyles);
