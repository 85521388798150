import React from 'react'
import { Grid, styled } from '@mui/material'
import PropTypes from "prop-types";
import CustomButton from "../../../components/Button/ButtonComp"
import LicenceHeaderStyles from './licenceHeaderStyles';
import { EditIcon, ExportIcon } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';

const LicenceHeader = (props) => {
  const { title, className, cancelAction, saveAction } = props
  const navigate = useNavigate();
  const handlesaveAction = () => {
    navigate('/licence/create');
  };
  return (
    <Grid className={`${className} header`}>
      <Grid className='Licence-header'>
        <h2 className="th-title">{title}</h2>
        <Grid className={'button_container'}>
          <CustomButton variant='outlined'
            onClick={cancelAction}
            startIcon={<ExportIcon />}
          >
            Export
          </CustomButton>
          <CustomButton variant='contained'
            onClick={handlesaveAction}
            startIcon={<EditIcon />}
          >
            Edit
          </CustomButton>
        </Grid>
      </Grid>



    </Grid>
  )
}
LicenceHeader.defaultProps = {
  title: "",
  cancelAction: () => { },
  saveAction: () => { }
}
LicenceHeader.propTypes = {
  title: PropTypes.string,
  cancelAction: PropTypes.func,
  saveAction: PropTypes.func
}
export default styled(LicenceHeader)(LicenceHeaderStyles)