const Style = () => ({
  // padding: '10px 20px',
  zIndex: 1,
  ".table_container": {
    background: "#FFF",
    borderRadius: "10px",
  },
  ".MuiTableContainer-root": {
    // padding: '18px'

  },
  ".MuiTableHead-root": {
    '& .arrow':{
      display: "none",
     },
    borderRadius: "12px",
    // display:"flex",
 "& .item-header": {
      position: 'relative',
      "& .arrow": {
        display: "none",
        position: "absolute",
        left: '55%',
        top:"50%",
        transform: 'translateY(-50%)',
      },
      "&:hover .arrow": {
        display: "block",
      },
    },
    '& .item-header-active': {

    },
 
    "& .MuiTableCell-head": {
      backgroundColor: "#F8FAFC", // Consistent background color for all header cells
    },
    "& .MuiTableCell-head:first-of-type": {
      borderTopLeftRadius: "12px",  // Rounded top-left corner for the first cell
      borderBottomLeftRadius: "12px" // Rounded bottom-left corner for the first cell
    },
    "& .MuiTableCell-head:last-of-type": {
      borderTopRightRadius: "12px", // Rounded top-right corner for the last cell
      borderBottomRightRadius: "12px" // Rounded bottom-right corner for the last cell
    },
  },
  ".MuiTableCell-root": {
    "& table_header": {
      backgroundColor: "red"
    }
  },
  ".MuiTableCell-head": {
    borderBottom: "none",

    "& .MuiTypography-root": {
      textTransform: "uppercase",
    },
  },
  ".MuiTableCell-body": {

  },
  ".pagination-wrapper": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    "& .form-label": {
      fontWeight: "bold",
    },
    "& .form-field": {
      marginLeft: "8px",
    },
  },
  ".th-wrapper": {
    display: "flex",
    padding: "8px 5px",
    alignItems: "center",
    justifyContent: "space-between",
    "& .th-title": {},
    "& .th-search": {},
  },
  ".button_container": {
    display: "flex",
    alignItems: "center"
  }
});

export default Style;
