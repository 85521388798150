import theme from "../../../theme";

const Style = () => ({
  ".filter": {
    borderRadius: "12px",
    border: "1px solid #EFEFEF",
    background: theme.palette.containers.card,
    boxShadow: "4px 4px 20px 0px rgba(0, 0, 0, 0.03)",
    height: '57px',
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center'
  },
  '.filterSelect': {
    marginRight: '16px',
    borderRadius: '7px',
    border: '1px solid #64748B',
    height: '24px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  '&.popover': {
    '& .MuiPaper-root': {
      padding: '16px',
      minWidth: '200px',
    }
  }
});

export default Style;
