import React from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';
import style from './style';


const Action = ({ rowData, classes,onEdit }) => {
  const handleView = () => {
    onEdit(rowData);
  };

  return (
    <Button onClick={handleView} className={'moreOption'}>
      View
    </Button>
  );
};


// Action.defaultProps = {
//     classes: {}
// };

// Action.propTypes = {
//     classes: PropTypes.object
// };


export default styled(Action)(style);

