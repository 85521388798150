import theme from "../../../theme";

const Style = () => ({
    '.cardContainer': {
        borderRadius: '12px',
        background: theme.palette.containers.card,
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
        padding: '16px'
    },
    '.users': {
        '& .MuiAvatar-root, & img': {
            height: '32px',
            width: '32px',
            fontSize: '14px'
        }
    },
    '.tags': {
        '& .MuiChip-root': {
            borderRadius: '6px',
            marginRight: '5px',
            marginBottom: '5px',
            height: '24px',
            background: theme.palette.containers.greyBg4,
            color: theme.palette.text.greyText1
        }
    }
})

export default Style;