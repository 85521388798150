import { oldVersionGetRequest, oldVersionPostRequest as postRequest } from "../../utils/apiHelper";

export const searchService = {
  getSearchdatas
};


async function getSearchdatas(data) {
  
    return await postRequest(`search/getAll`, data);
  }