import React, { useState } from 'react'
import { Grid, Button, Typography, styled } from '@mui/material'
import AddedByStyles from "./style"
import { FlozyLogo } from "../../assets/svg/index"
const AddedByComp = (props) => {

    const { className, data, heading = "Added by", addedBy, addedByRole, editedBy, editedByRole } = props
   
    return (
        <Grid className={`${className} AddedByComp`}>
            <Typography variant='h5' style={{ fontWeight: "600" }}>{heading}</Typography>
            <Grid className='added_by_comp_main'>
                <Grid className='image_container'>
                    {/* <img src='' alt='image'/> */}
                    <FlozyLogo />
                </Grid>
                <Grid className='details_conainer'>
                    <Grid className='name_container'>
                        <Typography style={{ fontWeight: 400, color: "#000", fontSize: "16px" }}>{addedBy ? addedBy : editedBy}</Typography>
                        <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#9AA6B7" }}>{ }</Typography>
                    </Grid>
                    <Typography style={{ fontWeight: 400, fontSize: "14px", color: "#9AA6B7" }}>{addedByRole ? addedByRole : editedByRole}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default styled(AddedByComp)(AddedByStyles)