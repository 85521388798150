import { Avatar, Button, Grid, Select, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import Style from "./Style";
import moment from 'moment';
import { LabTabs } from "../../components/Tabs/Tabs"
import {
  AgenciICon,
  AlertIcon,
  CheckFilled,
  DownloadIcon,
  FilledEmailIcon,
  InfiniteIcon,
  PhoneIcon,
  PinIcon,
  PlanTag,
  ProDimond,
  ShareCircleIcon,
  UserIcon,
  UsersIcon,
  InactiveIcon,
  TrialIcon
} from "../../assets/svg";
import PaymentCard from "./PaymentCard";
import AgencyPayment from "./AgencyPayment";
import Table from "../../components/Table";
import CreatePaymentLink from "./CreatePaymentLink";
import ChangePlan from "./ChangePlan";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { getAgenciesById, getAgencyDeatil } from "../../store/actions/agenciesAction"
import { setActiveFilterHeader, setOpenFilter } from "../../store/reducers/uiReducer"
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  addFilterRule,
  updateFilterRule,
  removeFilterRule,
  applyFilter,
  clearFilter,
} from "../../store/reducers/agenciesReducer";
import { Details } from "@mui/icons-material";
import theme from "../../theme";
import BillingAction from "./components/actions/billingInvoice/index"
const AgencyDetail = (props) => {
  const { className } = props;
  const { agency_id } = useParams()
  const dispatch = useDispatch()
  console.log(theme,"themeColor")
  const { loading, list } = useSelector((state) => state.agencies);
  const { filterHeader, filterBar } = useSelector((state) => state.ui);

  const [detail, setDetail] = useState("billingInvoices")
  const [TableHeader, setTableHeader] = useState(list?.billingInvoices)
  const {agencyDetails}  = list
  const {
    agency_email,
    agency_logo,
    agency_name,
    agency_plan,
    avatar_filename,
    first_name,
    id,
    last_name,
    plan_code,
    plan_name,
    plan_period,
    price,
    referral_code,
    username,
    status,
    clinetCount,
    agencyClientsUsersCount,
    subscriptionDetails,
    agencyInvoices,
    invoices,
    clients,
    stripe_id,
    sub_id
  } =agencyDetails && agencyDetails;
  const { pagination } = list

  const filters = [
    { key: "status", label: "Status", options: ['0', '1', '2'] },
  ];
  const formattedDate = (trialEnd) => {
  
    const convertedDate = new Date(trialEnd * 1000);


    // Check if the date is valid
    if (isNaN(convertedDate.getTime())) {
      return "Need to subscribe";
    }

    // Format the date as needed
    return convertedDate.toLocaleDateString();
  }
  useEffect(() => {
    dispatch(getAgenciesById(agency_id))
    dispatch(setActiveFilterHeader())
  }, [])

  const agencyName = (item) => {

    return <span>{item.rowData.agency_name
    }</span>;
  };

  const StatusCell = (props) => {

    const { item, rowData } = props
    
    const color = item === "paid" ? "#D4F6D2" : item === "draft" ? "#FFDEDE" : "orange";
    const textColor = item === "paid" ? "#0E8E2A" : item === "draft" ? "#FF3B3B" : "orange";
    const width = "100px"; // Set a specific width for consistency
    const renderText = item
    return (
      <span
        style={{
          display: "inline-block",
          width: width,
          textAlign: "center",
          color: textColor,
          backgroundColor: color,
          borderRadius: "6px",
          padding: "8px 10px",
        }}
      >
        {renderText}
      </span>
    );
  };
  const CreatedOnCell = ({ item }) => {
    const formatDate = (isoDate) => {
      return moment(isoDate).format('DD MMMM YYYY');
    };
    return <span>{formatDate(item)}</span>;
  };
  const AmoutCell = ({item}) => {
console.log(item,"see amount cell")
    return <span style={{color:theme.palette.colors.blue}} >{item}$</span  >;
  };
  const cellMap = {
    name: agencyName,
    status: StatusCell,
    amount_paid: AmoutCell,
    // profile_name: profileName,
    created_on: CreatedOnCell,
    action:BillingAction
  };
  const header = [
    { field: 'amount', label: 'Amount' },
    { field: 'created_on', label: 'Created On' },
    { field: 'status', label: 'Status' },
    // { field: 'username', label: 'User name' },
    { field: 'agency_email', label: 'Agency Email' },
    // { field: 'profile_name', label: 'Profile Name' },
    { field: "plan_name", label: "Plan" },

    { field: "action", label: "ACTION" }
  ]
  const cardData = [
    {
      name: "Infinity AI",
      icon: <InfiniteIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
    {
      name: "Infinity AI",
      icon: <ShareCircleIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
    {
      name: "Infinity AI",
      icon: <ShareCircleIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
    {
      name: "Infinity AI",
      icon: <ShareCircleIcon />,
      description:
        "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
      switchText: "Active since 12 Jun 2023",
    },
  ];
  const TabOption = [
    {
      name: "Billing Invoices",
      value: "billingInvoices"
    },
    {
      name: "Connected Invoices",
      value: "connectedInvoices"
    },
   
  ]

  const handleTabChange = (e, value) => {
    setDetail(e)
    setTableHeader(e === 'billingInvoices' ? list.billingInvoices : e === 'connectedInvoices' ? list.connectedInvoices :e === 'clients' ? list.clients : header )
    dispatch(getAgencyDeatil({ id: agency_id, detail:e, stripe_id:stripe_id?stripe_id:"" ,subscription_id:sub_id ? sub_id :"" , data: pagination }))
  }
 
  useEffect(() => {
 if(stripe_id && sub_id){

   dispatch(getAgencyDeatil({ id: agency_id, detail: detail, stripe_id:stripe_id?stripe_id:"" ,subscription_id:sub_id ? sub_id :"" , data: pagination }))
 }
    
  }, [sub_id])

  // useEffect(() => {
   
   

  //     dispatch(getAgencyDeatil({ id: agency_id, detail: detail, stripe_id:stripe_id?stripe_id:"" ,subscription_id:sub_id ? sub_id :"" , data: pagination }))
  

  // }, [stripe_id,sub_id,detail])
  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  return (
    <Grid container className={className}>
      {/* Agenci detil Header */}
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="breadcrumb">
        <Grid className="dflex aCenter">
          {/* <Typography variant="h5" className="fw-700" color={"text.greyText2"}>
            Agency {" > "}
          </Typography>
          <Typography variant="h5" className="fw-700">Agency Info</Typography> */}
        </Grid>
        {/* Create payment */}
        <CreatePaymentLink />
      </Grid>
      {/* Agenci detil Header */}
      {/* Agency Card */}
      <Grid item xs={12} className={`agenciDetailCard`}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item className="">
            <Grid item className="dflex aStart">
              <Grid item className="agenciName">
                <Grid>
                  <Avatar />
                </Grid>
                <Grid sx={{ ml: 2 }}>
                  <Typography
                    variant="h4"
                    className="fw-600"
                    sx={{ mb: 1 }}
                    color={'primaryText'}
                  >
                    {agency_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="dflex"
                    color={"text.greyText2"}
                  >
                    <AgenciICon />
                    <Grid sx={{ ml: 1 }}>Substance Digital Branding</Grid>
                  </Typography>
                </Grid>
                
              </Grid>
              <Grid item sx={{ ml: 3 }}>
                <Grid className={`${status === "Trial" ? "agenciTrail" : status === "Active" ? "agenciStatusActive" : "agenciInactive"} chipItem`}>
                  {status === "Trial" ? <TrialIcon /> : status === "Active" ? <CheckFilled /> : <InactiveIcon />}
                  <Typography
                    sx={{ ml: 1 }}
                    variant="body2"
                    color={'text.primaryText'}
                  >
                    {status === "Trial" ? "Trail" : status === "Active" ? "Active" : "Inactive"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          {/* <Grid item>
            <Grid className="agenciDate chipItem">
              <AlertIcon />
              <Typography variant="body2" color={"text.redText"} sx={{ ml: 1 }}>
                Expires by
              </Typography>
              <Typography
                variant="body2"
                color={"text.greyText1"}
                sx={{ ml: 2 }}
              >
                12 June 23{" "}
              </Typography>
            </Grid>
          </Grid> */}

          {/* Plan Card */}
          <Grid item className="planCard">
            <PlanTag />
            <Grid item className="agenciPlan chipItem">
              <ProDimond />
              <Typography
                variant="body1"
                className="ml-1 fw-600"
                color={"text.primaryText"}
              >
                {plan_name}
              </Typography>
              <Grid className="dflex aBaseline">
                <Typography
                  variant="h1"
                  color={"text.primaryText"}
                  className="fw-700 ml-3"              >
                  ${price}
                </Typography>
                <Typography
                  variant="body2"
                  color={"text.primaryText"}
                  className="pl5"
                >
                  USD/Month
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid className="dflex aCenter mb-1">
              <Typography variant="body2" color={"text.greyText3"}>Next Invoice On :</Typography>
              <Typography variant="body2" color={"textSecondary"}>{status === "Trial" ? formattedDate(subscriptionDetails?.trial_end) : status === "Active" ? formattedDate(subscriptionDetails?.current_period_end) : "Need to subscribe"}</Typography>
            </Grid> */}
            {/* <Grid className="dflex aCenter jSpaceBetween">
              <ChangePlan />
              <Grid className="dflex aCenter">
                <Typography variant="body2" color={"text.greyText3"}>Coupon Code :</Typography>
                <Typography variant="body2" className="fw-600" color={"textSecondary"}>AF@#@$</Typography>
              </Grid>
            </Grid> */}
          </Grid>
          {/* Plan Card */}
        </Grid>

        <Grid container justifyContent={"space-between"}>

          {/* Users Count */}
          <Grid item>
            <Grid container className="usersCount">
              <Grid item className="usersCountChip">
                <Grid className="UsersIcon">
                  <UsersIcon />
                </Grid>
                <Typography variant="body1" className="fw-600 ml-1" color={"text.greyText1"}>
                  Clients
                </Typography>
                <Grid className="dflex aBaseline ml-2">
                  <Typography variant="body1" className="fw-600" color={"text.blueText"}>
                    {clinetCount}
                  </Typography>
                  <Typography variant="body2" className="fs-10 pl5" color={"text.greyText1"}>
                    nos
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className="usersCountChip ml-3">
                <Grid className="UsersIcon">
                  <UserIcon />
                </Grid>
                <Typography variant="body1" className="fw-600 ml-1" color={"text.greyText1"}>
                  Users
                </Typography>
                <Grid className="dflex aBaseline ml-2">
                  <Typography variant="body1" className="fw-600" color={"text.blueText"}>
                    {agencyClientsUsersCount}
                  </Typography>
                  <Typography variant="body2" className="fs-10 pl5" color={"text.greyText1"}>
                    nos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Users Count */}

          {/* transaction card */}
          {/* <Grid item className="transactionCard">
            <Grid className="mr-3">
              <Typography variant="body2" className="fs-10" color={"text.greyText3"}>Total Transaction</Typography>
              <Typography variant="h4" className="" color={"text.blueText"}>$292321</Typography>
            </Grid>
            <Select size="small" />
          </Grid> */}
          {/* transaction card */}
        </Grid>

        {/* <Grid container className="agenciContact" spacing={5}>
          <Grid item className="dflex pt-3">
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <FilledEmailIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Email
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {agency_email}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3">
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <PhoneIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Phone
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                (562) 975-6876
                XXXXXXXXX
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3">
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <PinIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Address
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                45 Roker Terrace, Latheronweel, KW5 8NW, London
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3">
            <Grid sx={{ mr: 2 }}>
              <Avatar />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Owner
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {first_name}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      {/* // Agency Card */}

      {/* Agenci card */}
      {/* <Grid item xs={12}>
        <Grid container className="paymentCardRoot" spacing={3}>
          {cardData.map((item) => (
            <Grid item xs={12} sm={6} md={3}>
              <PaymentCard
                icon={item.icon}
                name={item.name}
                description={item.description}
                switchText={item.switchText}
              />
            </Grid>
          ))}
        </Grid>
      </Grid> */}
      {/* Agenci card */}

      {/* Agenci Payments */}
      <Grid item xs={12} sx={{ pt: 3 }}>
        <Grid container className="agencyPayments">
          <Grid item xs={12} className="top">
            <Grid container justifyContent={"space-between"}>
        <LabTabs onChange={handleTabChange} tabs={TabOption} tabValue={detail} />
             
              {/* <Button
                variant="text"
                size="small"
                startIcon={<DownloadIcon />}
                color="secondary"

              >
                Download
              </Button> */}
            </Grid>
          </Grid>
          <Grid item xs={12} className="pl-1 pr-1 pt-2">
            <Table
              // title={"Agency Payments"}
              data={list.results || []}
              cellMap={cellMap}
              headers={TableHeader}
              pagination={list.pagination}
              filters={filters}
              filterOptions={filters}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* // Agenci Payments */}
    </Grid>
  );
};

export default styled(AgencyDetail)(Style);
// const AgencyDetail = styled(AgencyDetailComponent)(({ theme }) => Style(theme));

// export default AgencyDetail;
