import React from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";

import UserCard from './Card'

const Users = (props) => {
  const { className } = props;

  //topCard

  const cardData = [
    {
      name: 'Brooklyn Simmons',
      designation: 'Customer Support Executive',
      groupname: 'Pro User Group',
      email: 'quiestexiwdfdi@agenciflow.com',
      phone: '+91 9845454698'
    }
  ]

  return (
    <Grid
      container
      className={className}
    >
     <Grid container justifyContent={"space-between"} alignItems={"center"}>
      <Typography variant="h4">User Management</Typography>
      <Button variant="contained">+ Create New User</Button>
     </Grid>
     <Grid item xs={12} className="mt-2">
      <UserCard
      data={cardData}
      />
     </Grid>
    </Grid>
  );
};

// default props
Users.defaultProps = {
  classes: {}
};

// prop types
Users.propTypes = {
  classes: PropTypes.object
};


export default styled(Users)(Style);
