import theme from "../../../../theme";

const Style = () => ({
    ".filter": {
        borderRadius: "12px",
        border: "1px solid #EFEFEF",
        background: theme.palette.containers.card,
        boxShadow: "4px 4px 20px 0px rgba(0, 0, 0, 0.03)",
        height: '48px',
        padding: '5px 24px',
        display: 'flex',
        alignItems: 'center'
    },
    '.filterSelect': {
        marginRight: '16px',
        borderRadius: '7px',
        border: '1px solid #64748B',
        height: '24px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

        overflowX: "scroll"
    },
    '.clearFilter': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
    },
    '&.popover': {
        '& .MuiPaper-root': {
            padding: '16px',
            minWidth: '200px',
        }
    }
});

export default Style;
