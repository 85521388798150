import React from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  styled,
} from "@mui/material";
import Style from "./style";
import {
  MarketingIcon, ReturnIcon, SalesIcon,
  TotalRevenuIcon
} from "../../assets/svg";
import CountCard from "../../components/CountCard";

const SalesDashboard = (props) => {
  const { className } = props;

  //topCard
  const countdata = [
    {
      name: 'Sales',
      count: '$1,234.00',
      icon: <SalesIcon />,
      percentage: 12

    },
    {
      name: 'Total Revenue',
      count: '$10,566.01',
      icon: <TotalRevenuIcon />,
      percentage: 35
    },
    {
      name: 'Return',
      count: '$956.00',
      icon: <ReturnIcon />,
      percentage: -5
    },
    {
      name: 'Marketing',
      count: '5,566.01',
      icon: <MarketingIcon />,
      percentage: 15
    },
  ]

  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
      spacing={3}
    >
      <CountCard
        data={countdata}
      />
    </Grid>
  );
};

// default props
SalesDashboard.defaultProps = {
  classes: {}
};

// prop types
SalesDashboard.propTypes = {
  classes: PropTypes.object
};


export default styled(SalesDashboard)(Style);
