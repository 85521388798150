import { configureStore, combineReducers } from "@reduxjs/toolkit";
import reducers from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default storage is localStorage for web

// Combine all reducers
const combinedReducer = combineReducers({ ...reducers });

// Logger middleware for development
const logger = (store) => (next) => (action) => {
  console.log("store before action:", store.getState());
  console.log("action:", action);
  next(action);
  console.log("store after action:", store.getState());
};

const middlewareOptions = { serializableCheck: false };

// Persist configuration
const persistConfig = {
  key: "root", // Key for the persisted state in storage
  storage,
  whitelist: ["auth",], // Array of reducers to persist (whitelist)
};

// Root reducer to handle reset state on logout
const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (m) =>
    process.env.NODE_ENV === "development"
      ? m(middlewareOptions).concat(logger)
      : m(middlewareOptions),
  devTools: process.env.NODE_ENV !== "production",
});

// Persistor object for persisting the store
export const persistor = persistStore(store);
