
import React, { useEffect, useState } from 'react';
import { Grid, Typography, styled, } from '@mui/material';
import userFormStyles from './userManagementFormStyles';
import FormHeader from '../../Addons/components/CreateAddonHeader/formHeader';
import { useForm, Controller } from 'react-hook-form';
import CustomInput from '../../../components/TextField';
import CustomSelect from '../../../components/InputSelect';
import { SwitchAccessShortcut } from '@mui/icons-material';
import SwitchComponent from '../../../components/Switch';
const UserManagementForm = (props, classes) => {
    const { className, } = props;
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({

    });


    return (
        <form >
            <Grid className={`${className} userManagementForm`}>
                <Grid>
                    <FormHeader title={'New User Group'} />
                </Grid>

                <Grid className='user_container'>
                    <Grid className='licence_add_details'>
                        <Grid>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>User Role Group Name</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>Create a group of users to access the dashboard with which limits role of these users.</Typography>
                        </Grid>
                        <Grid style={{ width: "100%" }}>
                            <Grid className='plan-details'>
                                <Grid className='licence-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Group Name</Typography>
                                    <Controller
                                        name="plan_code"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" />}
                                    />


                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: "10px" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Tags</Typography>

                                    <Controller
                                        name="plan_code"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" />}
                                    />

                                </Grid>

                            </Grid>


                            <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Description

                                </Typography>
                                <Controller
                                    name="url_code"
                                    control={control}
                                    render={({ field }) => <CustomInput {...field} placeholder="Enter URL" />}
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='licence-end'></Grid>



                    <Grid className='licence_add_details'>
                        <Grid>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>User Access</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>Create a user access (limitation) for the users belongs to the group created by you</Typography>
                        </Grid>
                        <Grid style={{ width: "100%" }}>
                            <Grid className='plan-details'>
                                <Grid className='licence-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Menu</Typography>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: "10px", alignItems: "center" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>User Access</Typography>

                                    <Grid style={{ display: "flex", flexDirection: "row", gap: "40px" }}>
                                        <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>All</Typography>
                                        <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>View</Typography>
                                        <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Edit</Typography>
                                        <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Delete</Typography>
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </form>
    );
};

export default styled(UserManagementForm)(userFormStyles);
